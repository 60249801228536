import React from 'react';

const JsGreen = () => (
  <svg viewBox="0 0 630 630" fill="#fff">
    <rect x={0} y={0} width={630} height={630} fill="#3c790a" />
    <path d="m212,497c11,17,20,31,40,31,19,0,32-8,32-37v-201h59v202c0,61-36,89-88,89-47,0-75-25-89-54" />
    <path d="m423,492c13,21,29,36,58,36,25,0,40-12,40-29,0-20-16-27-43-39l-15-6c-43-18-71-41-71-89,0-44,34-78,87-78,38,0,65,13,84,47l-46,30c-10-18-21-25-38-25-17,0-28,11-28,25,0,18,11,25,36,36l15,6c50,22,79,44,79,93,0,53-42,82-98,82-55,0-91-26-108-60" />
  </svg>
);

export default JsGreen;
