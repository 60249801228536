import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { createLogger } from 'ms-iso-logger';
import { connect } from 'react-redux';
import { Navigation } from '../navigation/Navigation';

import { getI18nInstance } from '../../i18n';

import { Footer } from '../footer/Footer';

const logger = createLogger('AppContainer');

const AppContainerComponent = (props) => {
  /*
  const TranslatableWrappers = <TenantThemeProvider {...props} />;

  if (!i18nIntForBrowser) {
    return TranslatableWrappers;
  }
  */
  logger.debug('AppWrapper called.');
  return (
    <I18nextProvider i18n={getI18nInstance(props.locale)}>
      <Fragment>
        <Navigation />
        <div className="content-container">
          <div className="content">
            {/* Mount child routes */}
            {props.children}
          </div>
        </div>
        <Footer />
      </Fragment>
    </I18nextProvider>
  );
};

AppContainerComponent.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string,
};

AppContainerComponent.defaultProps = {
  locale: '',
};

function mapStateToProps(state) {
  return {
    locale: state.locale,
  };
}

export const AppContainer =
  connect(
    mapStateToProps,
    dispatch => ({ dispatch }),
  )(AppContainerComponent);

AppContainer.displayName = 'Connect(AppContainer)';
