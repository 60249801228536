import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createLogger } from 'ms-iso-logger';
import { TabCard } from '../../components/tabCard/TabCard';
import './techStack.scss';

const logger = createLogger('TechStackTemplate');

export class TechStackTemplate extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    techstack: PropTypes.array.isRequired,
  };

  static createStarsOfFive(count) {
    let stars = '';
    const dif = 5 - count;
    for (let i = 0; count > i; i += 1) {
      stars += '&starf;';
    }
    for (let i = 0; dif > i; i += 1) {
      stars += '&star;';
    }
    return stars;
  }

  constructor() {
    super();
    logger.debug('TechStackTemplate initialized');
  }

  render() {
    const {
      t,
      techstack,
    } = this.props;

    return (
      <div className="tech-stack">
        { techstack.map((category) => {
          const CategoryIcon = category.icon;
          return (
            <div className="tech-stack-category" key={category.type}>
              <div className="tech-category-type">
                <div>
                  <div className="tech-category-title">
                    <CategoryIcon />
                  </div>
                  <div className="tech-category-desc">{category.type}</div>
                </div>
              </div>
              <div className="tech-category-tools">
                { category.tools.map(tool => (
                  <TabCard
                    key={tool.href[1]}
                    icon={tool.icon}
                    linksTo={`${tool.href[0]}?type=${tool.href[1]}`}
                    title={tool.title}
                    description={TechStackTemplate.createStarsOfFive(tool.vote)}
                  >
                    <div className="tab-content">
                      <div className="rating-description">
                        unparalleled knowledge &#8211; &#9733;&#9733;&#9733;&#9733;&#9733;<br />
                        excellent knowledge &#8211; &#9733;&#9733;&#9733;&#9733;&#9734;<br />
                        profound knowledge &#8211; &#9733;&#9733;&#9733;&#9734;&#9734;<br />
                        basic knowledge &#8211; &#9733;&#9733;&#9734;&#9734;&#9734;<br />
                        superficial knowledge &#8211; &#9733;&#9734;&#9734;&#9734;&#9734;<br />
                      </div>
                      <div className="tab-info">
                        <blockquote>
                          <p>
                            {tool.quote}
                          </p>
                          <div className="reference">
                            <cite>
                              <a href={tool.source.link}>{tool.source.name}</a>
                            </cite>
                          </div>
                        </blockquote>
                      </div>
                    </div>
                  </TabCard>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
