import React from 'react';

const Polymer = () => (
  <svg
    width="256px"
    height="178px"
    viewBox="0 0 256 178"
    version={1.1}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="translate(-1.000000, 0.000000)" fill="none">
      <g transform="translate(51.398438, 0.000000)">
        <path
          d="M103.202016,177.979687 L0.795164062,0.6046875 L52.0021172,0.6046875 L154.408969,177.979687 L103.202016,177.979687 L103.202016,177.979687 Z"
          fill="#FF4081"
        />
        <path
          d="M103.202016,177.979687 L128.802469,133.635937 L154.408969,177.979687 L103.202016,177.979687 L103.202016,177.979687 Z"
          fillOpacity={0.2}
          fill="#FFFFFF"
        />
        <path
          d="M77.6015625,133.635937 L103.202016,177.979687 L128.802469,133.635937 L77.6025703,133.635937 L77.6015625,133.635937 L77.6015625,133.635937 Z"
          fillOpacity={0.1}
          fill="#FFFFFF"
        />
        <path
          d="M52.0011094,89.2921875 L77.6015625,133.635937 L103.202016,89.2921875 L52.0021172,89.2921875 L52.0011094,89.2921875 L52.0011094,89.2921875 Z"
          fillOpacity={0.1}
          fill="#000000"
        />
        <path
          d="M52.0011094,89.2921875 L77.6015625,44.9484375 L103.202016,89.2921875 L52.0021172,89.2921875 L52.0011094,89.2921875 L52.0011094,89.2921875 Z"
          fillOpacity={0.2}
          fill="#000000"
        />
        <path
          d="M26.4016641,44.9484375 L52.0021172,89.2921875 L77.6025703,44.9484375 L26.4026719,44.9484375 L26.4016641,44.9484375 L26.4016641,44.9484375 Z"
          fillOpacity={0.3}
          fill="#000000"
        />
        <path
          d="M26.4016641,44.9484375 L52.0021172,0.6046875 L77.6025703,44.9484375 L26.4026719,44.9484375 L26.4016641,44.9484375 L26.4016641,44.9484375 Z"
          fillOpacity={0.4}
          fill="#000000"
        />
        <path
          d="M0.795164063,0.6046875 L26.4016641,44.9484375 L52.0021172,0.6046875 L0.795164063,0.6046875 L0.795164063,0.6046875 Z"
          fillOpacity={0.5}
          fill="#000000"
        />
      </g>
      <g transform="translate(0.000000, 44.343750)">
        <path
          d="M52.1936016,133.635937 L0.986648438,44.9484375 L26.5931484,0.6046875 L103.399547,133.635937 L52.1925938,133.635937 L52.1936016,133.635937 L52.1936016,133.635937 Z"
          fill="#536DFE"
        />
        <path
          d="M52.1936016,133.635938 L77.8001016,89.2921875 L103.400555,133.635938 L52.1936016,133.635938 L52.1936016,133.635938 Z"
          fillOpacity={0.2}
          fill="#FFFFFF"
        />
        <path
          d="M26.5931484,89.2921875 L52.1936016,133.635938 L77.8001016,89.2921875 L26.5931484,89.2921875 L26.5931484,89.2921875 Z"
          fillOpacity={0.1}
          fill="#FFFFFF"
        />
        <path d="M26.5931484,89.2921875 L52.1936016,44.9484375 L77.8001016,89.2921875 L26.5931484,89.2921875 L26.5931484,89.2921875 Z" />
        <path
          d="M0.986648437,44.9484375 L26.5931484,89.2921875 L52.1936016,44.9484375 L0.986648437,44.9484375 L0.986648437,44.9484375 Z"
          fillOpacity={0.1}
          fill="#000000"
        />
        <path
          d="M0.986648437,44.9484375 L26.5931484,0.6046875 L52.1936016,44.9484375 L0.986648437,44.9484375 L0.986648437,44.9484375 Z"
          fillOpacity={0.2}
          fill="#000000"
        />
      </g>
      <g transform="translate(26.203125, 0.000000)">
        <path
          d="M25.9904766,89.2921875 L0.390023438,44.9484375 L25.9904766,0.6046875 L51.5969766,44.9484375 L25.9904766,89.2921875 L25.9904766,89.2921875 Z"
          fill="#303F9F"
        />
        <path
          d="M51.5969766,44.9484375 L25.9904766,0.6046875 L0.390023438,44.9484375 L51.5969766,44.9484375 L51.5969766,44.9484375 Z"
          fillOpacity={0.2}
          fill="#000000"
        />
      </g>
      <g transform="translate(179.390625, 88.687500)">
        <path
          d="M26.4157734,89.2921875 L0.809273437,44.9484375 L26.4157734,0.6046875 L52.0162266,44.9484375 L26.4157734,89.2921875 L26.4157734,89.2921875 Z"
          fill="#3F51B5"
        />
        <path
          d="M52.0162266,44.9484375 L26.4157734,89.2921875 L0.809273437,44.9484375 L52.0162266,44.9484375 L52.0162266,44.9484375 Z"
          fillOpacity={0.2}
          fill="#000000"
        />
      </g>
      <g transform="translate(154.195312, 0.000000)">
        <path
          d="M77.2115391,133.635937 L0.405140625,0.6046875 L51.6120937,0.6046875 L102.819047,89.2921875 L77.2125469,133.635937 L77.2115391,133.635937 L77.2115391,133.635937 Z"
          fill="#7986CB"
        />
        <path
          d="M51.6110859,89.2921875 L77.2115391,133.635938 L102.818039,89.2921875 L51.6110859,89.2921875 L51.6110859,89.2921875 Z"
          fillOpacity={0.2}
          fill="#FFFFFF"
        />
        <path
          d="M51.6110859,89.2921875 L77.2115391,44.9484375 L102.818039,89.2921875 L51.6110859,89.2921875 L51.6110859,89.2921875 Z"
          fillOpacity={0.1}
          fill="#FFFFFF"
        />
        <path
          d="M26.0045859,44.9484375 L51.6110859,0.6046875 L77.2115391,44.9484375 L26.0045859,44.9484375 L26.0045859,44.9484375 Z"
          fillOpacity={0.1}
          fill="#000000"
        />
        <path
          d="M0.405140625,0.6046875 L26.0055938,44.9484375 L51.6120937,0.6046875 L0.405140625,0.6046875 L0.405140625,0.6046875 Z"
          fillOpacity={0.2}
          fill="#000000"
        />
      </g>
    </g>
  </svg>
);

export default Polymer;
