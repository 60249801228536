import React from 'react';

const Chrome = () => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version={1.1}
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 128 128"
    style={{ enableBackground: 'new 0 0 128 128' }}
    xmlSpace="preserve"
    width="64px"
    height="64px"
  >
    <g>
      <circle cx={64} cy={64} r={45} style={{ fill: '#FFFFFF' }} />
    </g>
    <g>
      <g>
        <path
          d="M86.9,64c0,4.2-1.1,8.1-3.1,11.4c0,0,0,0.1,0,0.1l-18.4,31.5c-0.5,0.9,0.2,1.9,1.1,1.9    C90.2,107.6,109,88,109,64c0-5.8-1.1-11.4-3.1-16.5c0-0.1,1-0.3,0.9-0.4H79.4C84,51.3,86.9,57.3,86.9,64z"
          style={{ fill: '#FCF09D' }}
        />
      </g>
      <g>
        <path
          d="M64,86.9c-8.5,0-16-4.7-19.9-11.6c0,0,0,0,0-0.1L25.8,42.9c-0.3-0.6-1.2-0.6-1.5,0C20.9,49.2,19,56.4,19,64    c0,22.5,16.5,41.1,38,44.4c0.2,0-0.1,1,0.1,1l13.7-23.6C68.7,86.5,66.4,86.9,64,86.9z"
          style={{ fill: '#8FE5B5' }}
        />
      </g>
      <g>
        <path
          d="M64,41.1h37c0.8,0,1.2-0.9,0.8-1.5C93.8,27.2,79.8,19,64,19c-14.1,0-26.7,6.5-34.9,16.6    c-0.2,0.2-0.2,0.5-0.1,0.8l11.8,20.9c0.3,0.5,1.1,0.5,1.3-0.1C45,47.9,53.7,41.1,64,41.1z"
          style={{ fill: '#FF697B' }}
        />
      </g>
      <g>
        <path
          d="M64,47.1c-9.3,0-16.9,7.6-16.9,16.9S54.7,80.9,64,80.9S80.9,73.3,80.9,64S73.3,47.1,64,47.1z"
          style={{ fill: '#76ECFF' }}
        />
      </g>
    </g>
    <g>
      <path
        d="M54.5,111c-0.2,0-0.4,0-0.6-0.1C31.9,106.2,16,86.5,16,64c0-7.2,1.6-14.2,4.7-20.7c0.7-1.5,2.5-2.1,4-1.4   c1.5,0.7,2.1,2.5,1.4,4C23.4,51.6,22,57.7,22,64c0,19.7,13.9,36.9,33.1,41.1c1.6,0.3,2.7,1.9,2.3,3.6C57.2,110,55.9,111,54.5,111z"
        style={{ fill: '#444B54' }}
      />
    </g>
    <g>
      <path
        d="M67.9,111.8c-1.5,0-2.9-1.2-3-2.7c-0.1-1.7,1.1-3.1,2.7-3.2C89.2,104,106,85.6,106,64c0-4.5-0.7-8.8-2.1-13   c-0.5-1.6,0.3-3.3,1.9-3.8c1.6-0.5,3.3,0.3,3.8,1.9c1.6,4.8,2.4,9.8,2.4,14.9c0,24.7-19.3,45.7-43.8,47.8   C68.1,111.8,68,111.8,67.9,111.8z"
        style={{ fill: '#444B54' }}
      />
    </g>
    <g>
      <path
        d="M100.8,41.1c-0.9,0-1.9-0.4-2.5-1.3c-0.7-1-1.4-2-2.2-2.9c-8-9.5-19.7-15-32.2-15c-11.8,0-23.1,5-31.1,13.8   c-1.1,1.2-3,1.3-4.2,0.2c-1.2-1.1-1.3-3-0.2-4.2C37.6,21.7,50.5,16,64,16c14.2,0,27.6,6.2,36.8,17.1c0.9,1.1,1.7,2.2,2.5,3.3   c1,1.4,0.6,3.2-0.7,4.2C102,41,101.4,41.1,100.8,41.1z"
        style={{ fill: '#444B54' }}
      />
    </g>
  </svg>
);

export default Chrome;
