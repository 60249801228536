//
// This is the client side entry point for the React app.
//

import React from 'react';
import { render, hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { renderRoutes } from 'react-router-config';

//
// PWA registration
//
import { notify } from 'react-notify-toast';

import './app.scss';

export const initializeApp = (routes, storeFactory, createDefaultState) => {
  //
  // Add the client app start up code to a function as window.webappStart.
  // The webapp's full HTML will check and call it once the js-content
  // DOM is created.
  require.ensure(
    ['./sw-registration'],
    (require) => {
      require('./sw-registration')(notify);
    },
    'sw-registration',
  );
  //
  const initState = createDefaultState();

  const start = (App) => {
    // eslint-disable-next-line no-underscore-dangle
    const initialState = window.__PRELOADED_STATE__;
    const jsContent = document.querySelector('.app-root');
    const store = storeFactory(initialState || initState);

    // eslint-disable-next-line no-underscore-dangle
    const reactStart = window.__PRELOADED_STATE__ && jsContent.innerHTML ? hydrate : render;

    reactStart(
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>,
      jsContent,
    );
  };

  window.webappStart = () => start(() => renderRoutes(routes));
};
