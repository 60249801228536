import React from 'react';

const Grunt = () => (
  <svg
    version={1.1}
    id="Layer_1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="360px"
    height="334.99px"
    viewBox="0 0 360 334.99"
    enableBackground="new 0 0 360 334.99"
    xmlSpace="preserve"
  >
    <g id="Layer_2" display="none">
      <g id="O3btSA_1_" display="inline">
        <image
          overflow="visible"
          enableBackground="new    "
          width={190}
          height={225}
          id="O3btSA_2_"
          xlinkHref="FD4F815BC2522FA9.jpeg"
          transform="matrix(1.8929 0 0 1.8844 0.1777 -0.7471)"
        />
      </g>
    </g>
    <g id="Layer_1_1_">
      <g>
        <path
          fill="#FBA919"
          d="M67.892,81.419c0,0,5.737,22.127,6.555,29.504c0.821,7.375-14.751,23.765-14.751,31.961c0,8.196,3.274,22.947,21.306,30.324c18.032,7.376,20.488,26.224,20.488,37.7c0,11.473-6.555,33.598-6.555,33.598s-4.096,54.908,18.029,64.742c22.125,9.833,17.21,9.833,22.125,9.833c4.916,0,40.977,14.755,62.283,9.018c21.308-5.737,27.041-12.292,27.041-12.292s21.31,1.637,27.865-17.214c6.559-18.847,10.654-58.183,8.195-76.214c-2.459-18.032-5.736-41.794,9.014-49.991c14.755-8.196,30.324-18.027,27.865-26.224c-2.459-8.196-15.568-19.67-15.568-19.67s-3.279-20.486-0.822-25.404c2.457-4.917,17.21-17.213,9.014-26.226c-8.191-9.014-22.947-9.014-22.947-9.014l-18.847,1.637c0,0-31.961-28.682-73.757-27.043c-41.794,1.639-68.021,27.043-68.021,27.043s-13.93-4.917-26.224,0C67.886,72.406,65.435,67.486,67.892,81.419z"
        />
        <path
          fill="#E78724"
          d="M142.775,203.437c0,0-16.229,24.815-28.133,42.425c-1.842,2.724,11.904,2.913,11.904,2.913L142.775,203.437z"
        />
        <path
          fill="#E78724"
          d="M150.502,151.989c0,0-4.892,12.205-7.572,18.675c-2.909,7.034-14,10.533-14,10.533s5.749-6.03,5.749-21.306c0-6.409,8.274-8.957,8.274-8.957L150.502,151.989z"
        />
        <path
          fill="#E78724"
          d="M145.56,158.89c0,0-10.023,11.931-28.633,5.249c-18.614-6.68-20.522-30.545-20.522-37.227c0-6.682,35.317,6.682,40.091,9.546S150.332,150.775,145.56,158.89z"
        />
        <path
          fill="#E78724"
          d="M75.261,31.399c0,0,17.18-3.343,28.633,5.726c11.458,9.067,14.794,16.228,14.794,16.228L99.383,68.656c0,0-9.328-4.325-12.192-16.735C84.328,39.512,86.237,33.786,75.261,31.399z"
        />
        <path
          fill="#E78724"
          d="M140.549,124.427c-7.397-10.5-19.587-15.08-29.32-15.839c-16.596-1.298-24.475,4.569-24.475,4.569s27.18,1.151,35.014,11.44c14.584,19.15,35.86,20.706,39.262,16.5c-3.934,0.196-8.262-1.753-12.894-6.133C145.242,132.224,142.896,127.76,140.549,124.427z"
        />
        <path
          fill="#E78724"
          d="M94.666,161.554c0,0-30.157-10.367-24.425-20.865c5.726-10.501,1.168-16.222,1.168-16.222s-16.839,12.57-12.548,25.932c2.637,8.202,7.061,14.14,31.354,25.525c11.652,21.643,2.406,60.6,2.96,60.783c0.923,0.307,16.36-7.63,15.306-44.077C108.16,181.438,107.265,166.159,94.666,161.554z"
        />
        <path
          fill="#E78724"
          d="M134.184,50.074c-12.645,6.424-29.968,15.37-29.968,15.37l6.818,3.411c-0.651,1.054-0.965,1.683-0.965,1.683l6.998,3.182c0,0,17.083,12.375,27.55,20.331c-3.49-10.746-13.734-27.628-13.734-27.628S127.98,58.666,134.184,50.074z"
        />
        <path
          fill="#E78724"
          d="M176.191,311.934c-38.866,0-52.031-19.731-52.031-19.731l6.074,14.791c2.296,6.399,26.788,26.311,45.898,26.311"
        />
        <path
          fill="#E78724"
          d="M128.419,278.871c0,0,3.576,21.854,17.894,21.854c12.167,0,15.752-18.46,15.752-22.275L128.419,278.871z"
        />
        <path
          fill="#E78724"
          d="M104.592,245.912c1.272-3.502,5.726-17.66,7.157-30.545c1.715-15.432,1.09-38.003,1.09-38.003s-3.82,14.795-7.161,17.186c-3.339,2.385-9.545,18.61-9.545,18.61s-3.324,24.156-4.906,32.275C95.605,246.312,99.291,243.732,104.592,245.912z"
        />
        <path
          fill="#E78724"
          d="M70.016,150.162c0,0,8.111-2.866,10.5-20.522c2.385-17.66-2.866-32.932-2.866-32.932s0.477,24.34-2.385,29.114c-2.866,4.771-5.249,6.203-8.592,12.409C63.33,144.438,68.104,148.252,70.016,150.162z"
        />
        <path fill="#87CCEE" d="M113.063,69.061" />
        <path
          fill="#FDC168"
          d="M79.701,35.576l-1.656-1.049C73.162,34.692,79.701,35.576,79.701,35.576z"
        />
        <path
          fill="#FBA919"
          d="M140.786,44.846c0,0-7.634-1.272-11.454-6.998c-3.82-5.728-14.636-17.184-31.879-17.312c-17.242-0.129-34.301,4.583-34.301,4.583l14.891,9.409c1.575-0.051,4.329-0.03,8.883,0.122c18.688,0.621,21.565,19.106,21.565,19.106l65.862-9.069"
        />
        <path
          fill="#FFFFFF"
          d="M147.151,146.942c0,0-8.91,8.91-22.593,6.044c-13.683-2.862-21.957-8.908-22.593-19.409c-0.636-10.5,2.866-14.636,2.866-14.636s15.271,4.776,22.593,11.14C134.742,136.443,140.15,146.625,147.151,146.942z"
        />
        <path
          fill="#C9C9C9"
          d="M136.368,143.759c0,0-7.727,3.53-16.364,0.655c-11.306-3.771-12.059-15.529-12.059-15.529s-6.936,20.354,12.51,24.272c15.597,3.142,24.821-5.896,24.821-5.896L136.368,143.759z"
        />
        <path fill="#463014" d="M145.249,257.84" />
        <path
          fill="#463014"
          d="M141.859,40.528c-0.831-17.456,13.479-31.359,18.437-31.951c0.274,2.958-0.276,14.496,4.681,18.044C166.42,17.197,171.3,6.807,190.57,0c-4.127,10.946-0.551,20.115,1.152,24.365c13.159-11.944,22.025-9.97,22.025-9.97s-9.143,15.777-5.567,26.129C181.764,38.453,141.988,43.331,141.859,40.528z"
        />
        <path
          fill="#463014"
          d="M85.857,67.117c-6.303-5.229-1.605-10.082-2.103-16.023c-0.271-3.161-0.521-6.144-1.563-7.789c-5.8-9.159-19.483-11.938-19.62-11.967l-6.422-1.258l4.251-4.973c0.551-0.644,18.009-17.301,45.025-8.579c18.898,6.095,32.888,26.763,33.409,27.598l-9.476,4.456c-0.111-0.176-11.171-19.603-27.384-24.831c-13.106-4.229-25.185-0.206-30.359,2.431c5.5,2.196,12.691,6.195,17.024,13.036c2.044,3.231,2.389,7.299,2.722,11.238c0.439,5.215,0.85,10.138,4.688,13.326L85.857,67.117z"
        />
        <path
          fill="#463014"
          d="M69.023,143.45c0,0,7.941-9.847,10.293-29.008c0.924-7.547-0.717-22.433-3.343-28.16c0.477,14.079-3.102,28.16-3.102,28.16S70.458,140.586,69.023,143.45z"
        />
        <path
          fill="#463014"
          d="M110.896,57.172c-0.004-0.021-2.548-11.138-9.601-16.75c-9.949-7.914-22.097-3.704-22.214-3.667l-1.764-5.449c0.588-0.191,14.473-4.566,27.269,4.429c12.873,9.046,14.418,17.246,14.552,18.152L110.896,57.172z"
        />
        <path
          fill="#463014"
          d="M93.964,136.388c0,0-4.166-5.964-3.417-12.523c0.373-3.239,1.874-6.564,4.554-7.744c4.98-2.188,7.187,3.457,7.187,3.457S95.279,121.308,93.964,136.388z"
        />
        <path fill="#463014" d="M145.249,257.84" />
        <path
          fill="#463014"
          d="M139.707,145.478c0.174,0.325,0.263,0.507,0.263,0.507s-12.994,9.752-26.311-0.144c-7.128-5.302-6.455-18.417-4.271-23.979c5.679,1.287,10.496,3.607,14.522,6.316c-5.088-4.541-9.542-7.884-9.53-7.961l-5.783-1.637l-11.24-3.854c-0.144,0.857-4.244,20.074,7.59,33.168c5.245,5.806,29.835,18.808,41.51-3.424L139.707,145.478z"
        />
        <path
          fill="#463014"
          d="M138.49,146.907c7.316,11.135,25.775,4.772,25.457-7c0,0-12.334,6.807-23.231-5.726c-6.362-7.317-26.748-27.492-54.394-20.787C105.414,117.592,130.689,135.035,138.49,146.907z"
        />
        <g>
          <path fill="#463014" d="M145.249,257.84" />
        </g>
        <path
          fill="#463014"
          d="M83.739,178.333c1.76,1.017,7.967,7.445,7.971,17.059c0,4.821-0.48,29.04-2.866,43.836c3.589,2.332,6.089,2.828,9.663,5.205c1.789-17.136,3.072-41.972,2.474-51.998c-0.684-11.429-6.803-19.099-12.478-22.37c-1.777-1.022-3.634-2.027-5.504-3.038c-9.72-5.252-19.771-10.685-19.771-19.962c0-7.257,2.821-9.527,5.808-11.933l0.322-0.261c1.982-1.601,4.225-3.417,5.554-6.399c3.626-8.153,4.219-28.921,0-34.193c-2.103-2.627-7.738-11.461-6.118-17.397c0.617-2.266,2.044-3.945,4.367-5.135c2.458-1.259,5.779-1.897,9.867-1.897c8.03,0,16.936,2.444,21.531,3.899c16.419,5.204,36.231,20.208,41.91,24.683c0.163,0.125,0.259,0.198,0.276,0.21l3.634,2.527l-2.273-3.803c-0.14-0.237-14.534-23.905-40.271-32.583c-5.007-1.688-14.844-4.521-24.511-4.521c-5.756,0-10.64,1.005-14.507,2.985c-4.713,2.411-7.905,6.258-9.231,11.121c-3.25,11.921,7.335,25.194,7.696,25.618c1.234,2.203,1.757,18.206-1.094,24.611c-0.392,0.884-1.467,1.753-2.828,2.851c-0.426,0.345-0.903,0.703-1.408,1.086c-4.022,3.036-10.104,7.623-10.104,18.196c0,14.467,14.662,22.311,26.445,28.618C80.226,176.388,82.071,177.372,83.739,178.333z"
        />
        <g>
          <g>
            <path
              fill="#463014"
              d="M85.857,67.117c-6.303-5.229-1.605-10.082-2.103-16.023c-0.271-3.161-0.521-6.144-1.563-7.789c-5.8-9.159-19.483-11.938-19.62-11.967l-6.422-1.258l4.251-4.973c0.551-0.644,18.009-17.301,45.025-8.579c18.898,6.095,32.888,26.763,33.409,27.598l-9.476,4.456c-0.111-0.176-11.171-19.603-27.384-24.831c-13.106-4.229-25.185-0.206-30.359,2.431c5.5,2.196,12.691,6.195,17.024,13.036c2.044,3.231,2.389,7.299,2.722,11.238c0.439,5.215,0.85,10.138,4.688,13.326L85.857,67.117z"
            />
          </g>
          <g>
            <path
              fill="#463014"
              d="M85.652,68.188l-0.407-0.337c-4.674-3.879-3.845-7.7-3.042-11.397c0.365-1.706,0.75-3.47,0.594-5.279c-0.255-3.042-0.5-5.912-1.416-7.361c-5.523-8.72-18.87-11.514-19.002-11.541L54.358,30.7l5.317-6.216c0.097-0.116,11.306-11.431,30.29-11.431c5.175,0,10.477,0.861,15.752,2.565c19.724,6.36,33.795,27.789,33.928,28.001l0.569,0.91l-11.209,5.272l-0.45-0.714c-1.206-2.056-11.834-19.583-26.869-24.433c-3.693-1.192-7.583-1.794-11.56-1.794c-7.203,0-13.044,1.963-16.152,3.281c5.194,2.339,11.493,6.276,15.479,12.562c2.167,3.424,2.518,7.609,2.862,11.652c0.422,5.028,0.821,9.756,4.348,12.684l1.331,1.104L85.652,68.188z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#463014"
              d="M110.896,57.172c-0.004-0.021-2.548-11.138-9.601-16.75c-9.949-7.914-22.097-3.704-22.214-3.667l-1.764-5.449c0.588-0.191,14.473-4.566,27.269,4.429c12.873,9.046,14.418,17.246,14.552,18.152L110.896,57.172z"
            />
          </g>
          <g>
            <path
              fill="#463014"
              d="M110.212,58.472l-0.248-1.094c-0.026-0.107-2.521-10.844-9.266-16.207c-3.844-3.061-8.48-4.615-13.771-4.615c-4.13,0-7.199,0.986-7.534,1.102l-0.912,0.303l-2.366-7.269l0.907-0.293c0.407-0.129,4.092-1.278,9.305-1.278c6.877,0,13.379,2.018,18.811,5.832c13.195,9.273,14.806,17.848,14.944,18.794l0.116,0.748L110.212,58.472z M86.928,34.65c5.652,0,10.829,1.738,14.961,5.024c5.989,4.77,8.806,13.191,9.658,16.21l6.492-2.587c-0.588-2.292-3.176-9.171-14-16.78c-5.179-3.64-11.136-5.484-17.711-5.484c-3.608,0-6.411,0.574-7.796,0.924l1.171,3.619C81.061,35.211,83.674,34.65,86.928,34.65z"
            />
          </g>
        </g>
        <path
          fill="#463014"
          d="M145.715,295.482L145.715,295.482c-2.82,0-5.698-1.023-7.693-2.75c-1.527-1.316-6.691-6.059-8.968-11.87c-0.85-2.173-0.659-3.911,0.574-5.162c0.85-0.857,2.614-1.882,6.195-1.882l2.548-0.004c3.036-0.003,8.148-0.003,12.517-0.003h4.189c2.724,0,4.677,0.589,5.8,1.737c1.442,1.482,0.98,3.367,0.577,4.359c-0.702,1.738-3.607,7.394-8.525,12.652C151.168,294.443,148.605,295.482,145.715,295.482z"
        />
        <path
          fill="#463014"
          d="M148.671,147.562l-10.182-0.655c0,0,2.603,4.588,4.289,10.522c1.904,6.712-1.741,16.521-1.741,16.521S154.613,158.58,148.671,147.562z"
        />
        <g>
          <path
            fill="#463014"
            d="M135.961,322.367c-36.239-2.785-48.989-37.028-48.989-44.16l9.464,0.004l-4.732-0.004h4.732c0.047,3.083,9.678,32.375,40.25,34.726L135.961,322.367z"
          />
        </g>
        <path
          fill="#FFFFFF"
          d="M125.777,261.016c-0.566-3.273-0.625-6.377,0.066-9.427c-2.754-2.07-7.696-2.839-11.404-4.041
c-3.032-1.919-18.548,1.967-29.805-11.656c-14.104-17.059-11.136-27.244-11.136-27.244c-3.616,4.018-7.121,2.487-12.633,21.221
c-5.52,18.738,2.591,31.801,17.708,44.915c7.142,6.195,17.238,8.747,25.922,9.724c-2.909-2.921,4.969-3.213,10.399-5.245
c-0.433-4.384,0.292-8.64,3.561-12.067c1.971-2.066,4.507-3.301,7.138-4.285c0.147-0.34,0.271-0.654,0.411-0.98
C125.917,261.633,125.832,261.332,125.777,261.016z"
        />
        <path
          fill="#C9C9C9"
          d="M114.821,279.149c-0.314-0.92-0.551-1.858-0.784-2.802c-0.251-1.031-1.501-3.952,0.04-0.617
c-0.396-0.854-0.655-1.734-0.846-2.621c-24.482-0.031-45.781-16.84-41.666-52.179c-3.205-4.255-7.693,8.58-7.693,8.58
s-12.256,35.588,19.398,49.381c10.547,4.6,19.868,7.897,22.067,6.566c4.581,0.931,8.716-2.263,10.267-4.363
C115.313,280.449,115.048,279.803,114.821,279.149z"
        />
        <path
          fill="#463014"
          d="M125.336,255.892c0.038-2.983,0.647-5.872,2.089-8.751c0.101-0.198,0.218-0.388,0.326-0.581
c-9.421-0.081-20.271-0.983-29.51-4.681c-10.155-4.062-22.858-17.959-25.041-34.287c0,0-19.125,14.758-13.505,46.346
c5.275,29.662,30.096,39.332,58.404,40.968c0.015-0.192-3.184-6.8-2.173-10.845c-16.074,1.168-34.049-5.042-42.726-19.32
c-7.944-13.068-5.716-35.379-2.451-38.648C78.713,251.222,107.369,256.33,125.336,255.892z"
        />
        <path fill="#463014" d="M176.191,294.839" />
        <path
          fill="#463014"
          d="M128.302,237.902c-5.094,14.949-15.499,27.047-15.499,27.047s14.313-0.651,15.103-3.184
c0.585-1.867,12.924-30.752,17.66-64.589C141.111,204.495,128.738,236.622,128.302,237.902z"
        />
        <path
          fill="#E78724"
          d="M209.393,203.437c0,0,16.225,24.815,28.133,42.425c1.842,2.724-11.908,2.913-11.908,2.913L209.393,203.437z"
        />
        <path
          fill="#E78724"
          d="M201.664,151.989c0,0,4.893,12.205,7.569,18.675c2.909,7.034,13.999,10.533,13.999,10.533s-5.748-6.03-5.748-21.306c0-6.409-8.273-8.957-8.273-8.957L201.664,151.989z"
        />
        <path
          fill="#E78724"
          d="M206.605,158.89c0,0,10.023,11.931,28.638,5.249s20.522-30.545,20.522-37.227c0-6.682-35.317,6.682-40.091,9.546C210.9,139.322,201.834,150.775,206.605,158.89z"
        />
        <path
          fill="#E78724"
          d="M276.906,31.399c0,0-17.18-3.343-28.637,5.726c-11.453,9.067-14.795,16.228-14.795,16.228l19.306,15.302c0,0,9.332-4.325,12.196-16.735C267.838,39.512,265.93,33.786,276.906,31.399z"
        />
        <path
          fill="#E78724"
          d="M211.618,124.427c7.397-10.5,19.583-15.08,29.32-15.839c16.597-1.298,24.472,4.569,24.472,4.569
s-27.18,1.151-35.015,11.44c-14.585,19.15-35.854,20.706-39.256,16.5c3.933,0.196,8.263-1.753,12.892-6.133
C206.924,132.224,209.271,127.76,211.618,124.427z"
        />
        <path
          fill="#E78724"
          d="M257.501,161.554c0,0,30.153-10.367,24.426-20.865c-5.726-10.501-1.172-16.222-1.172-16.222
s16.843,12.57,12.548,25.932c-2.637,8.202-7.061,14.14-31.354,25.525c-11.652,21.643-2.406,60.6-2.957,60.783
c-0.924,0.307-16.359-7.63-15.305-44.077C244.007,181.438,244.902,166.159,257.501,161.554z"
        />
        <path
          fill="#E78724"
          d="M217.984,50.074c12.641,6.424,29.967,15.37,29.967,15.37l-6.817,3.411
c0.651,1.054,0.966,1.683,0.966,1.683l-6.998,3.182c0,0-17.084,12.375-27.551,20.331c3.486-10.746,13.73-27.628,13.73-27.628
S224.189,58.666,217.984,50.074z"
        />
        <path
          fill="#E78724"
          d="M175.978,311.934c38.86,0,52.025-19.731,52.025-19.731l-6.07,14.791c-2.296,6.399-26.79,26.311-45.896,26.311"
        />
        <path
          fill="#E78724"
          d="M223.748,278.871c0,0-3.58,21.854-17.894,21.854c-12.165,0-15.751-18.46-15.751-22.275L223.748,278.871z"
        />
        <path
          fill="#E78724"
          d="M247.575,245.912c-1.272-3.502-5.726-17.66-7.161-30.545c-1.715-15.432-1.086-38.003-1.086-38.003
s3.819,14.795,7.156,17.186c3.344,2.385,9.546,18.61,9.546,18.61s3.324,24.156,4.906,32.275
C256.559,246.312,252.877,243.732,247.575,245.912z"
        />
        <path
          fill="#E78724"
          d="M282.154,150.162c0,0-8.115-2.866-10.5-20.522c-2.385-17.66,2.865-32.932,2.865-32.932s-0.48,24.34,2.385,29.114c2.866,4.773,5.25,6.203,8.592,12.409C288.834,144.437,284.062,148.252,282.154,150.162z"
        />
        <path fill="#87CCEE" d="M239.106,69.061" />
        <path
          fill="#FDC168"
          d="M272.465,35.576l1.659-1.049C279.008,34.692,272.465,35.576,272.465,35.576z"
        />
        <path
          fill="#FBA919"
          d="M211.378,44.846c0,0,7.638-1.272,11.454-6.998c3.819-5.728,14.639-17.184,31.883-17.312
c17.242-0.129,34.301,4.583,34.301,4.583l-14.892,9.409c-1.575-0.051-4.329-0.03-8.887,0.122
c-18.685,0.621-21.562,19.106-21.562,19.106l-65.86-9.069"
        />
        <path
          fill="#FFFFFF"
          d="M205.016,146.942c0,0,8.91,8.91,22.59,6.044c13.684-2.862,21.957-8.908,22.594-19.409c0.635-10.5-2.863-14.636-2.863-14.636s-15.275,4.776-22.593,11.14S212.018,146.625,205.016,146.942z"
        />
        <path
          fill="#C9C9C9"
          d="M215.796,143.759c0,0,7.73,3.53,16.364,0.655c11.309-3.771,12.062-15.529,12.062-15.529s6.932,20.354-12.514,24.272c-15.594,3.142-24.82-5.896-24.82-5.896L215.796,143.759z"
        />
        <path fill="#463014" d="M206.916,257.84" />
        <path
          fill="#463014"
          d="M266.309,67.117c6.303-5.229,1.609-10.082,2.107-16.023c0.266-3.161,0.516-6.144,1.559-7.789
c5.801-9.159,19.483-11.938,19.624-11.967l6.423-1.258l-4.252-4.973c-0.551-0.644-18.009-17.301-45.029-8.579
c-18.895,6.095-32.885,26.763-33.405,27.598l9.472,4.456c0.115-0.176,11.176-19.603,27.389-24.831
c13.105-4.229,25.184-0.206,30.355,2.431c-5.497,2.196-12.691,6.195-17.024,13.036c-2.044,3.231-2.386,7.299-2.718,11.238
c-0.439,5.215-0.854,10.138-4.693,13.326L266.309,67.117z"
        />
        <path
          fill="#463014"
          d="M283.14,143.45c0,0-7.937-9.847-10.289-29.008c-0.924-7.547,0.714-22.433,3.339-28.16c-0.477,14.079,3.105,28.16,3.105,28.16S281.709,140.586,283.14,143.45z"
        />
        <path
          fill="#463014"
          d="M241.271,57.172c0.004-0.021,2.548-11.138,9.602-16.75c9.948-7.914,22.097-3.704,22.21-3.667l1.769-5.449c-0.59-0.191-14.473-4.566-27.273,4.429c-12.873,9.046-14.414,17.246-14.552,18.152L241.271,57.172z"
        />
        <path
          fill="#463014"
          d="M258.203,136.388c0,0,4.166-5.964,3.413-12.523c-0.369-3.239-1.87-6.564-4.55-7.744c-4.98-2.188-7.188,3.457-7.188,3.457S256.888,121.308,258.203,136.388z"
        />
        <path fill="#463014" d="M206.916,257.84" />
        <path
          fill="#463014"
          d="M212.461,145.478c-0.174,0.325-0.264,0.507-0.264,0.507s12.99,9.752,26.307-0.144
c7.133-5.302,6.459-18.417,4.271-23.979c-5.676,1.287-10.492,3.607-14.52,6.316c5.089-4.541,9.543-7.884,9.527-7.961l5.787-1.637
l11.239-3.852c0.144,0.857,4.243,20.074-7.595,33.168c-5.244,5.806-29.834,18.808-41.506-3.424L212.461,145.478z"
        />
        <path
          fill="#463014"
          d="M213.674,146.907c-7.316,11.135-25.77,4.772-25.451-7c0,0,12.33,6.807,23.227-5.726c6.362-7.317,26.752-27.492,54.397-20.787C246.756,117.592,221.479,135.035,213.674,146.907z"
        />
        <g>
          <path fill="#463014" d="M206.916,257.84" />
        </g>
        <path
          fill="#463014"
          d="M268.431,178.333c-1.761,1.017-7.971,7.445-7.971,17.059c-0.004,4.821,0.477,29.04,2.866,43.836
c-3.59,2.332-6.09,2.828-9.664,5.205c-1.789-17.136-3.076-41.972-2.474-51.998c0.683-11.429,6.8-19.099,12.474-22.37
c1.777-1.022,3.635-2.027,5.509-3.038c9.72-5.252,19.771-10.685,19.771-19.962c0-7.257-2.824-9.527-5.811-11.933l-0.318-0.261
c-1.982-1.601-4.229-3.417-5.554-6.399c-3.627-8.153-4.222-28.921-0.004-34.193c2.103-2.627,7.742-11.461,6.122-17.397
c-0.621-2.266-2.049-3.945-4.371-5.135c-2.459-1.259-5.775-1.897-9.867-1.897c-8.025,0-16.936,2.444-21.527,3.899
c-16.419,5.204-36.231,20.208-41.914,24.683c-0.162,0.125-0.256,0.198-0.274,0.21l-3.636,2.527l2.273-3.803
c0.144-0.237,14.535-23.905,40.271-32.583c5.008-1.688,14.844-4.521,24.512-4.521c5.756,0,10.635,1.005,14.506,2.985
c4.714,2.411,7.904,6.258,9.232,11.121c3.25,11.921-7.336,25.194-7.697,25.618c-1.234,2.203-1.756,18.206,1.091,24.611
c0.392,0.884,1.467,1.753,2.831,2.851c0.426,0.345,0.899,0.703,1.408,1.086c4.023,3.036,10.102,7.623,10.102,18.196
c0,14.467-14.662,22.311-26.441,28.618C271.941,176.388,270.094,177.372,268.431,178.333z"
        />
        <path
          fill="#463014"
          d="M250.94,65.415c-2.332-3.95-8.296-11.577-22.042-18.279c-15.34-7.477-33.094-11.272-52.777-11.281v-0.004
c0,0-0.026,0-0.038,0c0,0-0.026,0-0.038,0v0.002c-19.686,0.01-37.443,3.805-52.779,11.281
c-13.746,6.703-19.708,14.329-22.042,18.279l-1.001,1.325l11.771,2.62l0.373-0.471l0.197-0.432
c0.182-0.778,1.76-4.978,14.884-11.378c14.03-6.843,30.394-10.312,48.633-10.318c18.241,0.006,34.598,3.475,48.627,10.318
c13.125,6.4,14.708,10.6,14.888,11.378l0.196,0.432l0.369,0.471l11.775-2.62L250.94,65.415z"
        />
        <g>
          <g>
            <path
              fill="#463014"
              d="M266.309,67.117c6.303-5.229,1.609-10.082,2.107-16.023c0.266-3.161,0.516-6.144,1.559-7.789
      c5.801-9.159,19.483-11.938,19.624-11.967l6.423-1.258l-4.252-4.973c-0.551-0.644-18.009-17.301-45.029-8.579
      c-18.895,6.095-32.885,26.763-33.405,27.598l9.472,4.456c0.115-0.176,11.176-19.603,27.389-24.831
      c13.105-4.229,25.184-0.206,30.355,2.431c-5.497,2.196-12.691,6.195-17.024,13.036c-2.044,3.231-2.386,7.299-2.718,11.238
      c-0.439,5.215-0.854,10.138-4.693,13.326L266.309,67.117z"
            />
          </g>
          <g>
            <path
              fill="#463014"
              d="M254.178,64.147l1.326-1.104c3.53-2.928,3.926-7.657,4.352-12.684c0.342-4.045,0.695-8.23,2.862-11.652
c3.982-6.284,10.286-10.222,15.479-12.563c-3.109-1.317-8.951-3.28-16.155-3.28c-3.979,0-7.862,0.602-11.556,1.794
c-15.039,4.85-25.666,22.377-26.869,24.433l-0.451,0.713l-11.209-5.271l0.57-0.91c0.132-0.212,14.199-21.641,33.924-28.001
c5.275-1.704,10.576-2.564,15.756-2.564c18.983,0,30.193,11.317,30.289,11.431l5.317,6.216l-8.022,1.573
c-0.133,0.027-13.479,2.819-19.006,11.541c-0.916,1.45-1.156,4.317-1.416,7.361c-0.151,1.81,0.229,3.574,0.599,5.279
c0.802,3.697,1.63,7.521-3.046,11.397l-0.406,0.337L254.178,64.147z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#463014"
              d="M241.271,57.172c0.004-0.021,2.548-11.138,9.602-16.75c9.948-7.914,22.097-3.704,22.21-3.667
l1.769-5.449c-0.59-0.191-14.473-4.566-27.273,4.429c-12.873,9.046-14.414,17.246-14.552,18.152L241.271,57.172z"
            />
          </g>
          <g>
            <path
              fill="#463014"
              d="M231.975,54.495l0.111-0.748c0.14-0.946,1.752-9.519,14.945-18.794
c5.428-3.814,11.932-5.832,18.809-5.832c5.216,0,8.901,1.147,9.309,1.278l0.906,0.293l-2.365,7.269l-0.913-0.303
c-0.341-0.115-3.409-1.102-7.537-1.102c-5.29,0-9.923,1.552-13.767,4.615c-6.746,5.365-9.243,16.101-9.266,16.207l-0.248,1.092
L231.975,54.495z M272.465,35.576l1.175-3.619c-1.386-0.352-4.192-0.924-7.8-0.924c-6.574,0-12.533,1.846-17.709,5.484
c-10.828,7.611-13.416,14.488-14.003,16.78l6.497,2.587c0.85-3.021,3.664-11.442,9.656-16.21
c4.135-3.286,9.306-5.024,14.958-5.024C268.489,34.65,271.107,35.211,272.465,35.576z"
            />
          </g>
        </g>
        <path
          fill="#463014"
          d="M199.242,292.559c-4.917-5.257-7.826-10.914-8.529-12.652c-0.402-0.99-0.86-2.877,0.582-4.359
c1.124-1.148,3.072-1.737,5.796-1.737h4.192c4.369,0,9.478,0,12.516,0.003l2.548,0.004c3.579,0,5.342,1.024,6.191,1.882
c1.234,1.249,1.427,2.987,0.577,5.162c-2.277,5.812-7.442,10.555-8.969,11.87c-2,1.727-4.871,2.75-7.691,2.75l0,0
C203.564,295.482,201.003,294.443,199.242,292.559z"
        />
        <path
          fill="#463014"
          d="M203.498,147.562l10.176-0.655c0,0-2.599,4.588-4.285,10.522c-1.904,6.712,1.741,16.521,1.741,16.521
S197.553,158.58,203.498,147.562z"
        />
        <path
          fill="#E78724"
          d="M176.085,111.211c-6.489,0-14.95-0.178-21.298-4.74c0,0,8.312,14.571,21.287,14.571l0.011-1.844
l0.011,1.844c12.976,0,21.287-14.571,21.287-14.571C191.035,111.033,182.568,111.211,176.085,111.211z"
        />
        <path
          fill="#463014"
          d="M193.952,111.141c-6.509,3.939-12.322,5.304-17.867,5.425c-5.546-0.121-11.357-1.486-17.867-5.425
c0,0,9.479,11.567,17.819,11.656v0.007c0.015,0,0.03-0.004,0.047-0.004c0.015,0,0.03,0.004,0.047,0.004v-0.007
C184.469,122.708,193.952,111.141,193.952,111.141z"
        />
        <g>
          <path
            fill="#463014"
            d="M216.207,322.367l-0.726-9.435c30.565-2.351,40.196-31.643,40.246-34.726h9.464
C265.195,285.337,252.441,319.582,216.207,322.367z"
          />
        </g>
        <path
          fill="#FFFFFF"
          d="M226.389,261.016c0.569-3.273,0.624-6.377-0.066-9.427c2.758-2.07,7.696-2.839,11.404-4.041
c3.032-1.919,18.552,1.967,29.809-11.656c14.104-17.059,11.132-27.244,11.132-27.244c3.619,4.018,7.121,2.487,12.637,21.221
c5.52,18.738-2.595,31.801-17.712,44.915c-7.141,6.195-17.238,8.747-25.922,9.724c2.909-2.921-4.969-3.213-10.396-5.245
c0.434-4.384-0.295-8.64-3.563-12.067c-1.967-2.066-4.507-3.301-7.136-4.285c-0.148-0.34-0.275-0.654-0.411-0.98
C226.252,261.633,226.334,261.332,226.389,261.016z"
        />
        <path
          fill="#C9C9C9"
          d="M237.342,279.149c0.318-0.92,0.551-1.858,0.784-2.802c0.255-1.031,1.505-3.952-0.038-0.617
c0.396-0.854,0.655-1.734,0.846-2.621c24.482-0.031,45.781-16.84,41.662-52.179c3.205-4.255,7.696,8.58,7.696,8.58
s12.257,35.588-19.397,49.381c-10.547,4.6-19.867,7.897-22.067,6.566c-4.585,0.931-8.72-2.263-10.267-4.363
C236.85,280.449,237.121,279.803,237.342,279.149z"
        />
        <path
          fill="#463014"
          d="M226.827,255.892c-0.034-2.983-0.647-5.872-2.085-8.751c-0.102-0.198-0.219-0.388-0.326-0.581
c9.42-0.081,20.271-0.983,29.51-4.681c10.154-4.062,22.857-17.959,25.041-34.287c0,0,19.125,14.758,13.505,46.346
c-5.279,29.662-30.097,39.332-58.404,40.968c-0.015-0.192,3.185-6.8,2.173-10.845c16.07,1.168,34.045-5.042,42.727-19.32
c7.939-13.068,5.712-35.379,2.447-38.648C273.454,251.222,244.795,256.33,226.827,255.892z"
        />
        <g>
          <path fill="#463014" d="M175.978,294.839" />
          <path
            fill="#463014"
            d="M238.258,263.332c-3.382-3.656-9.6-8.014-23.937-8.014c0,0-27.182-0.016-38.188-0.023l0,0
c-0.015,0-0.034,0-0.047,0c0,0-0.034,0-0.047,0l0,0c-11.005,0.008-38.194,0.023-38.194,0.023
c-14.333,0-20.551,4.359-23.935,8.014c-4.929,5.321-5.702,12.723-2.292,21.975c9.061,24.626,21.794,49.645,64.419,49.68v0.004
c0.016,0,0.03-0.004,0.047-0.004c0.015,0,0.03,0.004,0.047,0.004v-0.004c42.621-0.037,55.354-25.054,64.418-49.68
C243.959,276.055,243.184,268.653,238.258,263.332z M231.622,283.58c-4.317,12.366-15.919,42.298-55.539,42.35
c-39.625-0.052-51.223-29.983-55.54-42.35c-2.389-6.861-2.207-11.938,0.559-15.092c1.889-2.156,6.229-4.729,16.12-4.729
l10.333-0.004c7.623-0.007,17.194-0.015,28.529-0.019c11.331,0.004,20.907,0.012,28.528,0.019l10.329,0.004
c9.894,0,14.233,2.572,16.123,4.729C233.827,271.645,234.011,276.719,231.622,283.58z"
          />
        </g>
        <path
          fill="#463014"
          d="M223.863,237.902c5.094,14.949,15.498,27.047,15.498,27.047s-14.312-0.651-15.103-3.184
c-0.581-1.867-12.921-30.752-17.657-64.589C211.053,204.495,223.426,236.622,223.863,237.902z"
        />
      </g>
    </g>
  </svg>
);

export default Grunt;
