import React, { Component } from 'react';
import Collapse from '@kunukn/react-collapse';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createLogger } from 'ms-iso-logger';
import { isEqual } from 'lodash';
import process from 'process';
import { LinkCard } from '../linkCard/LinkCard';
import './tabCard.scss';

const logger = createLogger('TabCard');

@withRouter
export class TabCard extends Component {
  static propTypes = {
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    linksTo: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };
  static defaultProps = {
    children: null,
  };

  static isActiveTabCard(route, search = '') {
    return route.endsWith(search) && search !== '';
  }

  constructor(props) {
    super(props);

    this.cardRef = React.createRef();

    this.state = {
      contentStyle: {
        width: 0,
        left: 0,
      },
    };
  }

  componentDidMount() {
    if (process.browser) {
      this.adaptTabContentContainer();
      window.addEventListener('resize', this.resize.bind(this));
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.linksTo === nextProps.linksTo) {
      return true;
    } else if (isEqual(this.state, nextState)) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  resize() {
    logger.debug(`call resize of tabCard ${this.props.title}`);
    this.adaptTabContentContainer();
  }

  adaptTabContentContainer() {
    if (process.browser) {
      logger.debug(`adaptTabContentContainer of tabCard ${this.props.title}`);
      const rect = this.cardRef.current.getBoundingClientRect();
      this.setState({
        contentStyle: {
          width: window.innerWidth,
          left: rect.left * -1,
        },
      });
    }
  }

  render() {
    const {
      icon,
      title,
      linksTo,
      location,
      description,
      children,
    } = this.props;

    const tabCardContentStyle = {
      ...this.state.contentStyle,
      position: 'relative',
    };

    return (
      <div className="tab-card" ref={this.cardRef} >
        <div className={TabCard.isActiveTabCard(linksTo, location.search) ? 'tab-card-link-container active' : 'tab-card-link-container'}>
          <LinkCard
            title={title}
            linkDescription={description}
            icon={icon}
            linksTo={
              TabCard.isActiveTabCard(linksTo, location.search) ? location.pathname : linksTo
            }
          />
        </div>
        <div style={tabCardContentStyle}>
          <Collapse
            isOpen={TabCard.isActiveTabCard(linksTo, location.search)}
          >
            <div
              className="tab-card-content-container"
            >
              <div className="tab-card-content">
                {children}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}
