import React from 'react';

const Less = () => (
  <svg
    width="256px"
    height="110px"
    viewBox="0 0 256 110"
    version={1.1}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#2E4F82" offset="0%" />
        <stop stopColor="#182E4D" offset="100%" />
      </linearGradient>
      <path
        id="path-2"
        d="M224.236,88.738 C224.236,100.302 214.548,109.763 202.707,109.763 L21.75,109.763 C9.909,109.763 0.221,100.302 0.221,88.738 L0.221,21.781 C0.221,10.218 9.909,0.756 21.75,0.756 L202.707,0.756 C214.548,0.756 224.236,10.218 224.236,21.781 L224.236,88.738"
      />
      <path id="path-4" d="M-15.94,0.496 L240,0.496 L240,110 L-15.94,110" />
    </defs>
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g transform="translate(16.000000, 0.000000)">
        <mask id="mask-3" fill="white">
          <use xlinkHref="#path-2" />
        </mask>
        <use fill="url(#linearGradient-1)" xlinkHref="#path-2" />
        <mask id="mask-5" fill="white">
          <use xlinkHref="#path-4" />
        </mask>
        <use xlinkHref="#path-4" />
        <path
          d="M229.071,40.43 C229.071,32.233 230.332,27.399 230.332,18.572 C230.332,4.91 225.287,0.496 214.148,0.496 L205.951,0.496 L205.951,10.164 L208.473,10.164 C214.148,10.164 215.409,12.056 215.409,18.992 C215.409,25.507 214.778,32.023 214.778,39.59 C214.778,49.258 217.931,53.041 224.236,54.512 L224.236,55.143 C217.931,56.614 214.778,60.398 214.778,70.066 C214.778,77.632 215.409,83.728 215.409,90.664 C215.409,97.81 213.938,99.701 208.473,99.701 L208.473,99.911 L205.951,99.911 L205.951,110 L214.148,110 C225.287,110 230.332,105.586 230.332,91.925 C230.332,82.887 229.071,78.263 229.071,70.066 C229.071,65.652 231.803,61.028 240,60.608 L240,49.678 C231.803,49.468 229.071,44.844 229.071,40.43"
          fill="#FAF9F8"
          mask="url(#mask-5)"
        />
        <path
          d="M135.961,53.251 C129.445,50.729 123.56,49.258 123.56,45.054 C123.56,41.902 126.082,40.01 130.706,40.01 C135.33,40.01 139.534,41.902 144.158,45.265 L152.565,34.125 C147.31,30.132 140.164,25.928 130.496,25.928 C116.204,25.928 106.535,34.125 106.535,45.685 C106.535,55.984 115.573,61.238 123.14,64.181 C129.655,66.703 135.961,68.805 135.961,73.008 C135.961,76.161 133.438,78.263 127.764,78.263 C122.509,78.263 117.255,76.161 111.58,71.747 L103.383,83.938 C109.688,89.192 119.356,92.765 127.343,92.765 C144.158,92.765 153.195,83.938 153.195,72.378 C153.195,60.818 144.158,55.984 135.961,53.251"
          fill="#FAF9F8"
          mask="url(#mask-5)"
        />
        <path
          d="M186.824,53.251 C180.519,50.729 174.634,49.258 174.634,45.054 C174.634,41.902 177.156,40.01 181.78,40.01 C186.404,40.01 190.608,41.902 195.232,45.265 L203.639,34.125 C198.384,30.132 191.238,25.928 181.57,25.928 C167.277,25.928 157.609,34.125 157.609,45.685 C157.609,55.984 166.647,61.238 174.213,64.181 C180.729,66.703 187.034,68.805 187.034,73.008 C187.034,76.161 184.512,78.263 178.837,78.263 C173.583,78.263 168.328,76.161 162.654,71.747 L154.246,83.938 C160.552,89.192 170.22,92.765 178.207,92.765 C195.021,92.765 204.059,83.938 204.059,72.378 C204.059,60.818 195.021,55.984 186.824,53.251"
          fill="#FAF9F8"
          mask="url(#mask-5)"
        />
        <path
          d="M61.977,52.2 C63.238,43.793 68.282,39.8 74.167,39.8 C81.734,39.8 84.677,45.054 84.677,52.2 L61.977,52.2 L61.977,52.2 Z M100.65,55.984 C100.86,38.959 91.612,25.718 73.957,25.718 C58.614,25.718 43.902,38.539 44.322,58.716 C44.322,79.524 57.984,91.714 76.059,91.714 C83.626,91.714 92.033,88.982 98.548,84.568 L92.243,73.429 C87.619,76.161 83.205,77.422 78.581,77.422 C70.174,77.422 63.658,73.429 61.977,63.76 L100.02,63.76 C100.23,62.289 100.65,59.347 100.65,55.984 L100.65,55.984 Z"
          fill="#FAF9F8"
          mask="url(#mask-5)"
        />
        <path
          d="M43.271,77.002 C41.8,77.002 39.908,75.741 39.908,71.747 L39.908,0.496 L10.062,0.496 C-1.287,0.496 -6.332,4.91 -6.332,18.572 C-6.332,27.609 -5.071,32.654 -5.071,40.43 C-5.071,44.844 -7.803,49.468 -16,49.888 L-16,60.818 C-7.803,61.028 -5.071,65.652 -5.071,70.066 C-5.071,77.843 -6.332,82.466 -6.332,91.504 C-6.332,105.166 -1.287,109.58 9.852,109.58 L18.049,109.58 L18.049,99.911 L15.527,99.911 C10.273,99.911 8.591,97.81 8.591,90.874 C8.591,83.938 9.222,78.053 9.222,70.276 C9.222,60.608 6.069,56.824 -0.237,55.353 L-0.237,54.723 C6.069,53.251 9.222,49.468 9.222,39.8 C9.222,32.233 8.591,26.138 8.591,19.202 C8.591,12.266 10.062,10.375 15.527,10.375 L21.202,10.375 L21.202,70.696 C21.202,83.517 25.616,91.925 38.437,91.925 C42.43,91.925 45.583,91.294 47.895,90.453 L45.793,76.792 C44.532,77.002 43.902,77.002 43.271,77.002"
          fill="#FAF9F8"
          mask="url(#mask-5)"
        />
      </g>
    </g>
  </svg>
);

export default Less;
