import React from 'react';

const Mysql = () => (
  <svg
    id="Layer_1"
    style={{ enableBackground: 'new 0 0 180.6 95.3' }}
    version={1.1}
    viewBox="0 0 180.6 95.3"
    xmlSpace="preserve"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      className="st0"
      d="M1.7,85.3h5.8v-23l9.1,20c1,2.4,2.5,3.3,5.4,3.3c2.9,0,4.2-0.9,5.3-3.3l9-20v23h5.8v-23c0-2.2-0.9-3.3-2.8-3.9  c-4.4-1.3-7.4-0.2-8.7,2.8L21.8,81l-8.6-19.8c-1.3-3-4.3-4.1-8.7-2.8C2.6,59,1.7,60,1.7,62.3L1.7,85.3L1.7,85.3z"
    />
    <path
      className="st0"
      d="M46.9,66.6h5.8v12.6c0,0.7,0.2,2.3,3.4,2.4c1.7,0,12.6,0,12.7,0V66.5h5.8c0,0,0,20.6,0,20.7  c0,5.1-6.3,6.2-9.2,6.2H47.1v-3.9c0,0,18.4,0,18.4,0c3.7-0.4,3.3-2.3,3.3-2.9v-1.5H56.4c-5.8,0-9.5-2.6-9.5-5.5  C46.9,79.3,47.1,66.7,46.9,66.6L46.9,66.6z"
    />
    <path
      className="st0"
      d="M172.3,51.9c-3.6-0.1-6.3,0.3-8.7,1.2c-0.7,0.3-1.7,0.3-1.8,1.1c0.4,0.4,0.4,0.9,0.7,1.4  c0.5,0.9,1.5,2.1,2.3,2.7c0.9,0.7,1.9,1.4,2.9,2.1c1.7,1.1,3.7,1.7,5.4,2.8c1,0.6,2,1.4,2.9,2.1c0.5,0.4,0.8,0.9,1.4,1.2  c0,0,0-0.1,0-0.1c-0.3-0.4-0.4-1-0.7-1.4c-0.4-0.4-0.9-0.8-1.3-1.3c-1.3-1.7-2.9-3.3-4.6-4.5c-1.4-1-4.6-2.3-5.1-4c0,0,0,0-0.1-0.1  c1-0.1,2.1-0.4,3.1-0.7c1.5-0.4,2.9-0.3,4.5-0.7c0.7-0.2,1.4-0.4,2.1-0.6c0-0.1,0-0.3,0-0.4c-0.8-0.8-1.4-1.9-2.2-2.6  c-2.3-2-4.8-3.9-7.4-5.5c-1.4-0.9-3.2-1.5-4.6-2.2c-0.5-0.3-1.4-0.4-1.7-0.8c-0.8-1-1.3-2.3-1.8-3.4c-1.3-2.5-2.5-5.2-3.7-7.8  c-0.8-1.7-1.3-3.5-2.3-5.1c-4.6-7.6-9.6-12.2-17.3-16.7c-1.7-0.9-3.6-1.3-5.7-1.8c-1.1,0-2.2-0.1-3.3-0.2c-0.7-0.3-1.4-1.2-2.1-1.6  c-2.5-1.6-9.1-5.1-11-0.5c-1.2,2.9,1.8,5.8,2.8,7.2c0.8,1,1.7,2.2,2.3,3.3c0.3,0.8,0.4,1.6,0.7,2.4c0.7,2,1.4,4.1,2.3,6  c0.5,0.9,1,1.9,1.7,2.8c0.4,0.5,1,0.7,1.1,1.5c-0.6,0.9-0.7,2.2-1,3.3c-1.6,5-1,11.3,1.3,15c0.7,1.1,2.4,3.6,4.7,2.6  c2-0.8,1.6-3.3,2.1-5.6c0.1-0.5,0-0.9,0.3-1.2c0,0,0,0.1,0,0.1c0.6,1.2,1.2,2.5,1.8,3.7c1.4,2.2,3.8,4.5,5.8,6  c1.1,0.8,1.9,2.2,3.3,2.7c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c-0.3-0.4-0.7-0.6-1-0.9c-0.8-0.8-1.7-1.8-2.3-2.7c-1.9-2.5-3.5-5.3-5-8.1  c-0.7-1.4-1.3-2.9-1.9-4.3c-0.3-0.5-0.3-1.3-0.7-1.6c-0.7,1-1.7,1.8-2.1,3c-0.8,1.9-0.9,4.3-1.2,6.7c-0.2,0-0.1,0-0.2,0.1  c-1.4-0.4-1.9-1.8-2.5-3.1c-1.3-3.2-1.6-8.3-0.4-11.9c0.3-0.9,1.7-3.9,1.1-4.8c-0.3-0.8-1.2-1.3-1.7-2c-0.6-0.8-1.2-1.9-1.6-2.9  c-1.1-2.5-1.6-5.3-2.8-7.8c-0.5-1.2-1.5-2.4-2.2-3.4c-0.8-1.2-1.8-2.1-2.5-3.5c-0.2-0.5-0.5-1.3-0.2-1.8c0.1-0.4,0.3-0.5,0.6-0.6  c0.6-0.5,2.2,0.1,2.8,0.4c1.7,0.7,3,1.3,4.4,2.2c0.6,0.4,1.3,1.3,2.1,1.5c0.3,0,0.6,0,0.9,0c1.4,0.3,3,0.1,4.4,0.5  c2.4,0.8,4.5,1.9,6.4,3.1c5.8,3.7,10.7,9,13.9,15.3c0.5,1,0.8,2,1.2,3c0.9,2.2,2.1,4.4,3,6.6c0.9,2.1,1.8,4.2,3.2,6  c0.7,0.9,3.3,1.4,4.6,1.9c0.9,0.4,2.3,0.8,3.1,1.2c1.5,0.9,3,2,4.5,3C169.9,49.9,172.2,51,172.3,51.9L172.3,51.9z"
    />
    <path
      className="st0"
      d="M126.8,13.2c-0.8,0-1.3,0.1-1.8,0.2c0,0,0,0,0,0.1c0,0,0,0,0.1,0c0.4,0.7,1,1.2,1.4,1.8c0.4,0.7,0.7,1.4,1,2.1  c0,0,0.1-0.1,0.1-0.1c0.6-0.4,0.9-1.2,0.9-2.2c-0.3-0.3-0.3-0.6-0.5-0.9C127.7,13.7,127.1,13.5,126.8,13.2L126.8,13.2z"
    />
    <path
      className="st1"
      d="M79.1,85.3h16.8c2,0,3.8-0.4,5.4-1.1c2.5-1.2,3.7-2.7,3.7-4.8v-4.3c0-1.7-1.4-3.2-4.1-4.3  c-1.4-0.5-3.2-0.8-5-0.8h-7c-2.4,0-3.5-0.7-3.8-2.3c0-0.2,0-0.4,0-0.5v-2.6c0-0.1,0-0.3,0-0.5c0.3-1.2,0.9-1.5,3-1.7  c0.2,0,0.4,0,0.5,0h16.6v-3.9H88.9c-2.4,0-3.6,0.1-4.7,0.5c-3.4,1.1-5,2.8-5,5.8V68c0,2.6,2.9,4.9,7.9,5.4c0.5,0,1.1,0,1.7,0h6  c0.2,0,0.4,0,0.6,0c1.8,0.2,2.6,0.5,3.2,1.2c0.4,0.4,0.4,0.7,0.4,1.1v3.4c0,0.4-0.3,0.9-0.8,1.4s-1.4,0.8-2.5,0.8  c-0.2,0-0.4,0-0.6,0H79.1L79.1,85.3L79.1,85.3z"
    />
    <path
      className="st1"
      d="M141.3,78.5c0,4,3,6.2,9,6.6c0.6,0,1.1,0.1,1.7,0.1h15.2v-3.9h-15.3c-3.4,0-4.7-0.8-4.7-2.9V58.4h-5.8  L141.3,78.5L141.3,78.5z"
    />
    <path
      className="st1"
      d="M108.7,78.7V64.9c0-3.5,2.5-5.7,7.4-6.3c0.5,0,1.1-0.1,1.6-0.1h11.1c0.6,0,1.1,0,1.7,0.1  c4.9,0.7,7.3,2.8,7.3,6.3v13.8c0,2.9-1,4.4-3.4,5.4l5.7,5.2h-6.7l-4.6-4.2l-4.7,0.3h-6.2c-1,0-2.2-0.1-3.4-0.4  C110.5,83.9,108.7,81.9,108.7,78.7L108.7,78.7z M115,78.4c0,0.2,0.1,0.4,0.1,0.6c0.3,1.6,1.8,2.5,4.1,2.5h5.3l-4.8-4.4h6.7l4.2,3.8  c0.8-0.4,1.3-1.1,1.5-1.9c0-0.2,0-0.4,0-0.6V65.2c0-0.2,0-0.4,0-0.5c-0.3-1.5-1.8-2.4-4.1-2.4h-8.8c-2.6,0-4.3,1.1-4.3,2.9L115,78.4  L115,78.4z"
    />
    <path
      className="st1"
      d="M169.8,85.3h0.8V82h1.1v-0.7h-3.1V82h1.2L169.8,85.3L169.8,85.3z M176.3,85.3h0.8v-3.9h-1.2l-1,2.7l-1.1-2.7  h-1.2v3.9h0.8v-3h0l1.1,3h0.6l1.1-3l0,0L176.3,85.3L176.3,85.3z"
    />
  </svg>
);

export default Mysql;
