import React from 'react';

const Git = () => (
  <svg
    version={1.1}
    id="Layer_1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="325px"
    height="325px"
    viewBox="0 0 325 325"
    enableBackground="new 0 0 325 325"
    xmlSpace="preserve"
  >
    <image
      id="image0"
      width={325}
      height={325}
      x={0}
      y={0}
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUUAAAFFCAYAAAB7dP9dAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAf
WElEQVR42u3de7xlZX3f8c86wwiCoijosMUkoolEPGm9UPGlIpOCoGEcQBlTrWxtuIjWeDmKUlPn
QeuNsIGmTQSx2qOY6IAIHBSp6JBGHUlAhS2ttcYr7Bk0obVFmAvMyh97r+HMmXPm7L0uz+95nvV9
v17z4sUMZ6/f2uz1mWff1soQ8Sh37mDgROAFwJOAzujXfsBmYDD6dSfwpcy571nPLO2SWQ8g6cud
Owg4B3g5cBQwNcGP/xy4Abg8c+5W632R9CmK0pjcuf2BtwLvBB5bw01eBbwnc+4H1vsm6VIUpRG5
c68BLgRW1XzTDwKXAzOZcw9Y76ekR1GUWuXOrQAuAN7e8KZuA07OnLvLep8lLYqi1CZ37rHAZ4ET
PG1yC3Bq5twm632XdCiKUovcuVXA14Df9bzpbcC6zLnrrO8DSYOiKJWNgrgROMJohO3AaQqj1EFR
lEoCCGJBYZRaKIpSWkBBLCiMUpmiKKUEGMSCwiiVKIoysYCDWFAYpTRFUSYSQRALCqOUoijK2CIK
YkFhlIkpijKWCINYUBhlIoqiLCviIBYURhmboih7lUAQCwqjjEVRlCUlFMTCDuCVCqPsjaIoi0ow
iAWFUfZKUZQ9JBzEgsIoS1IUZTctCGJBYZRFKYqyS4uCWFAYZQ+KogCtDGJBYZTdKIrS5iAWFEbZ
RVFsOQVxF4VRAEWx1RTEPSiMMtFFySUhCuKiVgJX5c6ttR5E7Gil2EIK4rJ2MPxK4LXWg4h/imLL
KIhjUxhbSlFsEQVxYgpjCymKLaEglqYwtoyi2AIKYmUKY4soiolTEGujMLaEopgwBbF2CmMLKIqJ
UhAbozAmTlFMUO7cFHAb8M+tZ0mUwpgwfaMlQZlzO4HzgG3WsyRqJXClvvmSJq0UE5Y7dyJwDbCv
9SyJ0ooxQYpi4hTGximMiVEUW0BhbJzCmBBFsSUUxsYpjIlQFFtEYWycwpgARbFlFMbGKYyRUxRb
SGFsnMIYMUWxpRTGximMkVIUW0xhbJzCGCFFseUUxsYpjJFRFEVhbJ7CGBF991nInPsycDL6rnRT
iu9Kn2w9iCxPK0XZRSvGxu0A1mXOXWM9iCxNUQzU6HyIHwLenDl3n8ftKozNUhgDp6fPAZp3gtjX
ATfkzj3K17b1VLpxK4ENeiodLq0UA7PEGbO/DrxUK8akaMUYKEUxIMtcQkBhTI/CGCBFMRBjXlNF
YUyPwhgYRTEAE15kSmFMj8IYEEXRWMmr7imM6VEYA6EoGqp4GVKFMT0KYwAURSM1XZdZYUyPwmhM
UTRQ84XqFcb0KIyGFEXPag5iQWFMj8JoRFH0aBTEm4GnN3DzCmN6FEYDiqInDQexoDCmR2H0TFH0
wFMQCwpjehRGj3RCiIblzh2KvyACvBCdRCI1OomER1opNmgUxI34C+J8WjGmRytGDxTFhhgHsaAw
pkdhbJii2IBAglhQGNOjMDZIUaxZYEEsKIzpURgboijWKNAgFhTG9CiMDVAUaxJ4EAsKY3oUxpop
ijWIJIiF0MK4E/gBcB9wyOjX/tZ3UmR2AK/KnPuC9SApUBQriiyIBaswfhb4IfCdeb9uz5y7f8F/
ewAPB/JQ4CTgVcCB1ndcwHYAR2fOfdt6kNgpihXlzp0HfNB6jhK8h7GK3LlHAqcAXeA49MWDhT4J
nJE5t9N6kNgpijXInbsYeKv1HCVEFcZC7txhwGuBNwKHWc8TAAWxRopiTRRG/3LnHg18iGEc2/pY
VhBr1tYHUiMURhu5cy8APgUcbj2LZwpiAxTFmimMNnLnDgGuBZ5vPYsnCmJD9GJ1zTLn3gZcYj1H
Cd7PrlOnzLlfAr8PXGk9iwcKYoO0UmyIVow2cuemgKuBtdazNERBbJhWig3RitHGKBavBv7OepYG
lA7iZbeetf9Fd5z+QusdiIGiuBduLj/UXZefV/bnFUYbow+DrwF+bj1LjSoF8deP2DqXZ9nXev3u
KdY7EjpFcQluLh9+UyXjg+66/OKyt6Mw2sicuwc423qOmtQRxN9neAbvzymMe6coLmIUxJspvrqX
8VaFMT6ZczcAf2U9R0V1BbGgMC5DUVxgXhB/Z7c/UBijDCPDN7t+ZT1ESXUHsaAw7oWiOM+SQSwo
jNGFMXPuF8BnrOcooakgFhTGJSiKI8sGsaAwRhdGYNZ6gAk1HcSCwrgIRZEJglhobxhfADzWeohJ
Zc79LfA/recYk68gFhTGBVofxYmDWGhnGDdlzt1lPURJMawWfQexoDDO0+oolg5ioX1h3GA9QAVX
MDzLd6isglhQGEdaG8XKQSy0K4zXWg9QVubc3cBN1nMswTqIBYWRlkbRzeUd6ghioR1hvDdz7ifW
Q1R0o/UAiwgliIXWh7F1URwFcSN1BbGQfhhvtx6gBr+wHmCB0IJYaHUYWxXFxoJYSDuMKUTxl9YD
zBNqEAtFGE81u4eMtCaKjQexkG4Y77YeoAahrBRDD2JhJfDZtoWxFVH0FsRCmmHcbj1ADUJYKcYS
xELrwph8FL0HsZBeGLdZD1AD6yjGFsRCq8KYdBTNglhIK4wPWg9QVebcNuD/G20+1iAWWhPGZKNo
HsRCOmF8gvUAVeXO7QvsZ7Dp2INYaEUYk4xiMEEspBHGJxtvvw7TDA9sn1IJYiH5MCYXxeCCWIg/
jClE8bmet5daEAtJhzGpKAYbxELcYfxdo+3W6Tket5VqEAvJhjGZKAYfxEK8YXxq7txvGmy3Tr6i
mHoQC0l+wDuJKEYTxEK8YTzeYJu1GL3J8kwPm2pLEAv7kFgYo49idEEsxBnGl3jeXp2eQvNvsrQt
iIWkwhh1FGs/241v8YVxTe7cwR63V5vMue8D725wE20NYiGZMEYbxXlB/G3rWSqJK4z7AWd42lbt
Muc+QjNhbHsQC0mEMbMeoIxkgjhfziXu5dnbSv+4cxczvJxn034GHJ4595Cvu6ZuuXPvAj5c080p
iHt6EHjVzPTs1daDlBHdSjHJIEJMK8bfAF7v625pQo0rRgVxcVGvGKNaKSYbxPniWDHeCxyROWd9
goVKKq4YFcTlRblijGal2IogQiwrxscBF/m8W5owWjG+q8SPKojjiXLFGMVKsTVBnC+OFeMrM+c+
7/V+aUDu3LnAR8b8zxXEyUW1Ygw+iq0MYiH8MD4AHDu62HzUxgyjglheNGEMOoqtDmIh/DDeAzwv
c+6nvu+aui0TRgWxuijCGOxrigriSPivMT4RuCl37um+75q6Zc5dwOKvMSqI9YjiNcYgo6ggLhB+
GJ8G3JI7d6Lvu6ZuozCeO++3FMR6FWF8hfUgSwnu6bOCuBfhP5V+CDg3cy76d6Zz597J8HRpCmIz
HgT+cGZ6Nrg36oKKooI4hvDDCLCBYRyjfp0xdy7LnMsn/TkFcWxBhjGYKLq5/EkMz3ajIC4njjBu
By4DPpA5d4/He8eUgjix4MIYRBQVxBLiCCPArwGXOXehr7vGioJYWlBhNH+jRUEsKfw3XwoHAK/0
dbdYURAr2YfhpQ2CePPFNIoKYkXxhLF2uXOvyJ0L4pmOgliLYMJoFkUFsSbtDeOZwJ9bh1FBrFUQ
YTSJooJYs/aG8RwMw6ggNsI8jN6jqCA2RGH0GsbLbj1rpYLYGNMweo2igtgwhdFbGM9+7sd25Fn2
JesdT5hZGL1FUUH0RGH0FsaZ6dke8A7rHU+YSRi9RFFB9ExhVBjT4T2MjUdxFMSbURD9UhgVxnR4
DWOjUZwXxKf52BlZQGH0HcYZ6x1PmLcwNhZFBTEQCqPPMF6EwtgkL2FsJIoKYmDaHcZ/7XODCmPj
Gg9j7VFUEAPVzjB+EviM740qjI1rNIy1RlFBDFy7wlj6jNl1UBgb11gYa4uighiJdoTRNIiFURjf
bn1nJKyRMNYSRQUxMmmHMYggFmamZy9GYWxS7WGsHEUFMVJphjGoIBYUxsbVGsZKUVQQI5dWGIMM
YkFhbFwRxsonNC4dRQUxEWmEMeggFkZhLH0JCVnWPsBfVQ1jqSgqiImpJ4ylf76i/0KFy5BedMfp
b+n1uz4/4H0Ji4dxG3AbcPnoz/+E4V82VwBfBm4FfjL672RplcM48YNBQUxY1YthnX/+m8jzSxg+
MOe7JXPu6FpHde4GsuzmbP36j5T5+QUniP0o8KaZ6dmJL2daVq/fPQc4Evj26NedM9OzO8b4uUcB
pwFd4BgCufhcgB4E/tXM9OxVk/7gRHeogtgC1cO4mjy/Enj8vN+uP4rnn39Utn7935X52SXOmO09
jFX1+t3fAk5nGMjDrecJUKkwjh1FBbFFql8+9XDgUuD40W/VHsWylrmEQHRhLPT63WOAC4GjrGcJ
zMRhHOs1RQWxZaq/xvijzLmXAKuBb1rvTmGMa6qcA/y5z9cY6zIzPfvfgaMZXsv7Put5AjLxa4zL
/s93c/lhDE8QqyC2TcUV466bOf/8o7P1679luSsTXmQq2hUj7HpaPQc803qWgIy9YtxrFBVEqSuM
lkpedS/2MD4G+ALD1boMjRXGJZ8+K4gCDJ9KX59fZD1GWRUuQxrtU2mAmenZXwEnAhO/+5qwsZ5K
LxpFN5cfjIIohZy3ubn8j63HmNSGDaetuG/f7VdXuAxp7GHczvB8kpusZwnIsmHcI4puLl/J8G8X
BVHmu8hdnx9nPcQkfn7E/hdCfkLFm4k9jNuAU4C7rGcJSBHGYxf7w8VWin8GvNh6agnOCnI2uGvz
KP6yvOiO7uvJeGtNNxd7GO8B1gL3W88SkH2Aq3r97lMW/sFuUXRz+RnAG6ynlWAdxBSfsB5iORff
/vpn5xmX1nyzsYfx28DZ1nME5vHAtaNvCe2yK4puLj8QKPWVKWmVF51/Xf5S6yH2ZufUzguBRzRw
07GH8QrgFus5AjMN/Of5vzF/pTgDPM56QglfnvGBPM+DDMPFd5x+HM1+DCXqMALnWQ8QoNf2+t1d
n+mcgl3vNkf9WTTx6lnvm+NU6yEWszPLPuhhM9GGcWZ6diPwFes5AjMFvH/+vwD8W+DR1pNJPPKM
11jPsNCFd3SPwd93f88BYv38plaLezq51+8eBQ9H8WTriSQ6x7sNeROv25WX8XLPWzzYepfLmJme
vQ24w3qOAJ0DMOXm8icD/8x6GonOo3gkx1oPsbv8JM8b/Lz1HlfwJesBAvSyXr+bTQG+H0iSiowT
rUcoXPA/XvfbGdnTPW7yPoZnxI7VF60HCNATgaOmgBdaTyKRyvkt6xEK+zzo/TyCfz0zPbvVer8r
2AT8H+shAnTSFHCo9RQSrVXWAxRydvqeJerX5GamZx9ieI5U2d30FAE9sCU6wTx28sz7LH3rfa7B
T6wHCNAqRVGqeHz1m6jHlP/H8fes97kGm60HCNCqKWB/6ykkWv/XeoBCnrHC8ybvtd7nGiiKe3ri
FHCP9RQSrS3WAzxsyvcsKVx/eWA9QIB2TBHUA1siE8xjJ8u9z5JCFH9tPUCAtiiKUsXPrQco7PT/
OI7ue8+L0PsJe9oyBXzfegqJ1k3WAxRydt7ueZMd632ugT6Ot6fNU+iT7VLOtgMeEc7ZVt45/ak7
8Lty/Q3rfa5BCmGv27emgG+Qxjtp4lPOxneekAX1mlTu9y/4J1vvbw20UtzT3JRbkz1E3N/hFAtT
XGk9wkI5XO9xc8+x3t8aPNt6gMB8f2Z69u+LU4d93HoaicqPDl3Fp62HWOjX//DTG4Efetrc8db7
W0Wv310FPMt6jsBcB8WZt9dkG4GvWk8kkchYf/Zzsx3WYyzkVt/8YJ7xXk+be1qv3/1N632u4KWk
8Q56XbYC/wl2v0bLe6ynkijcya38pfUQS5k5cvazmb+TNcR82r0/sB4gMB+dmZ69C+ZfzW9Ndgvw
BevJJGjbp+BM57Kd1oMsJcvId2Y7zwVyD5t7o/X+ltHrdx9J5E//a3Yf8KHiX6YW/OFZwI+tJ5Qw
ZfCG967JNlnPsZx3PPPTN+bZwxciatAzev1ujHF5M3Cg9RABef/M9Owvi3/ZLYpuTfYPwFqG5RR5
WM4l69dkn7QeY1wzR846/DzzieoqmL1+9zHAu63nCMiGmenZC+b/xsKVIm5N1gdei5+nHxKH656x
lXdYDzGJLCPfb+r+02n+vIcv7fW7L7He3wmcCxxkPUQgvgO8fuFvTi32X7o12TXAm1AYBa7jAU5b
ty57yHqQSb3pyCvve2gqewnNf5X1L3r97n7W+7uc0cdw3mI9RyC2AGtnpmfvX/gHU0v9hFuTfRSF
se2u4wFOc+uy7WV+OM/dVH63Oy4fuLdb7cC5R/7XLQ9NZatpNoxPBf691T6Oo9fvrgA+BRxgPUsA
tgCrZ6ZnF/1a6NTeflJhbLVqQbz7/LVs5rtkfAVYZ7kjnsL4rl6/G8zVDRdxEXrHGR4O4pKPhanl
bmEUxjeiMLZJ6SDmA3dMPnC3kOXXANPWO1LwEMYVwIZev/t71vu6UK/fPQP4Y+s5ArBsEGGMKAK4
NdmlKIxtUT6Im907gI3Av7DeicV4COOjget7/W4wZ5/p9bvHAH9hPUcAxgoijBlFUBhbolQQ8x+7
/fKB+xQ5f8oEj6kq8jvdPmV+zkMYnwx8q9fvmn+vuNfvrgNuAFZaz2Js7CDChA9ghTFp5YL40w8d
xL7czPBjXP4cxEfyez/8mDI/6imMX+/1u6d5vU9Gev1u1ut33wd8Dl2YbqIgQom/1RXGJJUP4spt
NwHPM5j5SLZuvTHgMO4PfK7X757f63e9nXih1+8eAFxF4O+GezJxEKHkUx2FMSlVg2h5Tr7nBR7G
DHgv8I1ev/viJu+IXr871et3X8Pww+qnNrmtSJQKIlR4/UdhTELMQSyEHkaA5wM39/rdG3v9bu0n
p+31u2uA7wJXAE9pcD9iUTqIUMP51Nxc/gaG727p3Gxx8RnEW7KOO7rO4fOB+zJwwvxtsN9+J2SP
e/evytzeBXe+btWKnflG4Ig651xsdIZnCP888OWZ6dlS113v9btPBV4GvBqo9b6NXKUgQk0hUxij
43uF6COKEE8Yd+0GcBvDa8tsAjYzvED9P85Mz+aw65soT2B4PZXDgGMZngvxdzzNGJPKQYQaI6Yw
RsPiKbOvKEJ8YVzMDoYH+ErgEIYfDJe9qyWIUONnyvQaYxRSeA1xOTG8xriclQw/1rMKBXEctQUR
av6grcIYtDYEsZBCGGU8tQYRGvj2gcIYpDYFsaAwpq/2IEJDX8lSGIPSxiAWFMZ0NRJEaPB7qgpj
ENocxILCmJ4twLFNBBEa/vK+wmhKQXyYwpiOIoj/q6kNNH5GE4XRhIK4J4Uxfo0HETyd5klh9EpB
XJrCGC8vQQRPUQSF0RMFcXkKY3w24ymI4DGKsCuM56AwNkFBHJ/CGI/NDN9l9hJE8BxFALcmuwyF
sW4K4uQUxvB5DyIYRBEUxpopiOUpjOEyCSIYRREUxpooiNUpjOExCyIYRhEUxooUxPoojOEwDSIY
RxEUxpIUxPopjPbMgwgBRBEUxgkpiM1RGO0EEUQIJIqgMI5JQWyewuhfMEGEgKIICuMyFER/FEZ/
ggoiBBZFUBiXEHsQHzTefhkKY/OCCyIEGEVQGBeIOYhbgR6wtoHb/qGH+RXG5gQZRAj8IlNuLj8b
+GjoczYo1iA+CHwCeF/WcXc3tZF84F4BXMzweiZNSuFiWCEJNogQQWxaHMZYg/gj4OSs4/o+NpYP
3P7Ae4FzafYxojDWI+ggQiShaWEYqwTxK8BzjOb+KrAu67h7fW84H7hTgCuA/RvcjMJYTfBBhEBf
U1yoZa8xxhrEPwNOtAgiQNZxXwBeCDT2dB29xlhFFEGEyFZeLVgxxhrEd2Udd4HRtne/LwbuScAm
mn2dUSvGyUQTRIgwLgmHMdYgXpF13GuNtr34fTJwzwK+jp5KhyCqIEIkT5/nS/SpdKxBvBU402jb
S8o67jvA62j2MaKn0suLLogQYRQhuTDGGsR7gFOyjttqtP29yjruSuD9DW9GYVxalEGESKMIyYQx
1iAC/FHWcXcZbn8cjuHri01SGPcUbRAhgdfl3Fx+FnBphPsScxD/Juu4Ywy3P7Z84FYDX/OwKb3G
OBR1ECHilWLBrck+BryBuFaMMQcR4Dzj7Y8t67iN+ImiVowJBBESiCJEF8bYg3h91nHfMJ5hUn/i
aTttDmMSQYREogjRhDH2IAKstx5gUlnHbQK+4mlzbQxjMkGEhKIIwYcxhSD+JOu4b1sPUdJVHrfV
pjAmFURILIoQbBhTCCLAl6wHqOCLnrfXhjAmF0RIMIoQXBhTCSJEHMXRKcy+43mzKYdxM3BsakGE
RKMIwYQxpSBuxc+7uE2aM9hmimEsgvgD60GakGwUwTyMKQUR4LtZxz1gPURF3zTabkphTDqIkHgU
wSyMqQURhgdD7Cz3IYUwJh9EaEEUwXsYUwwiwMB6gAT2IeYwtiKI0JIogrcwphpESGOl+I/ADuMZ
Ygxja4IILYoiNB7GckH8sXtsBEGEBKKYdVwObLGeg7jC2KogQsuiCI2FsXwQ9+Umwg8iwD7WA9Rk
pfUAIzGEsXVBhBZGEWoPYxuCCNCxHqCqfOBWAIdYzzFPyGFsZRChpVGE2sLYliACHGo9QA2eAKyw
HmKBEMPY2iBCi6MIlcPYpiBCGlEMdR9CCmOrgwgtjyKUDmPbgghwmPUANWjyCn9VhRDG1gcRFEVg
4jC2MYgAv5cP3OOsh6hotfUAy7AM4wAFEVAUdxkzjG0NIgxfizvBeoiKTrIeYAwWYRwwPNtN64MI
iuJuRmE8m8XD2OYgFl5mPUBZ+cAdATzVeo4x+QyjgriAoriAW5Ndzp5hrBLEGD6YPa4T84GL9TET
wypxPh9hVBAXEesDvFELwlg1iM+13p8aHQwcbz1ESa+2HqCEJsOoIC5BUVzCKIwnKoh7+A/WA0wq
H7hTgGdZz1FSE2FUEPcitmslBy/xIBZekXXc1dZDjGP0dP924JnWs1RU13WlD0RB3CutFGvUkiAC
vD+i1xb/kPiDCPWsGF8MvEBB3DutFGvSoiAWzsw67uPWQ+xNPnCPBO4AnmY9S40qrRhlebH8bR+0
FgYR4D/mAxf663SfIK0gQsUVoyxPUayopUEE2B+4Jh+4J1gPsph84N7D8KlzihTGBunpcwUtDuJ8
fwP8y6zjrM9ovUs+cCcDV5P+41tPpRuglWJJ+Y/dI9iX/0a7gwjwIuDyfOCCOAltPnDPBz5N+kEE
rRgboSiWlD3FbQcux/a60qHoAjdanzAiH7gusBF4lPUd4pHCWLM2/G3aqHzgzgQuQ/clwI+AtVnH
fc/nRkdn1P5T4G3Wd4AhPZWuiVaKFWUdt9h3pdvqcGBTPnDrfG0wH7hVwA20O4gwXDF+Jr/3wzqm
K9LqpiZaMe7hm8C/yzrur5u48XzgHgOcC7wFOMB6ZwMwAFZnHacPZlekA7hGCuOibgTek3XcbXXc
2OgD2W8G3g0cZL1zgVAQa6SDt2YK45JuB64f/frbrON2jvuD+cAdzPBcjicxPNHtgdY7ExAFsWY6
cBugMC7rF8BXgZ8xvDj9PaN/3s/winurgCeO/vls4Hno9e/FKIgN0EHbEIVRGqYgNkQHbIMURmnI
ADg267j/bT1IinSwNiwfuDOAj6H7WuqhIDZMB6oHCqPUREH0QAepJwqjVKQgeqID1COFUUpSED3S
wemZwigTUhA904FpQGGUMSmIBnRQGlEYZRkKohEdkIYURlmCgmhIB6MxhVEWUBCN6UAMgMIoIwpi
AHQQBkJhbD0FMRA6AAOiMLaWghgQHXyBURhbR0EMjA68ACmMraEgBkgHXaAUxuQpiIHSARcwhTFZ
CmLAdLAFTmFMjoIYOF33InBZx30cOAtdVzoFd6MgBk+rj0hoxRi9uxleU0VBDJwOsIgojNFSECOi
gysy+cD9EXA5+n8XCwUxMjqwIqQwRkNBjJAOqkgpjMFTECOlAypiCmOwFMSI6WCKnMIYHAUxcjqQ
EqAwBkNBTIAOokQojOYUxEToAEqIwmhGQUyIDp7EKIzeKYiJ0YGTIIXRGwUxQTpoEqUwNk5BTJQO
mIQpjI0pznbzQ+tBpH46WBKnMNZOQUycDpQWUBhroyC2gA6SllAYK1MQW0IHSIsojKUpiC2ig6Nl
FMaJKYgtowOjhRTGsSmILaSDoqUUxmUpiC2lA6LFFMYlKYgtpoOh5fKB+zfAx9FjoaAgtpwOBFEY
H6YgSusPAhlRGBVEGWrrASCLaHEYFUTZpW0PfllGC8OoIMpu2vLAlwm0KIwKouwh9Qe9lNSCMCqI
sqhUH/BSg4TDqCDKklJ7sEvNEgyjgih7lcoDXRqUUBgVRFlW7A9y8SSBMCqIMpZYH+BiIOIwKogy
ttge3GIswjAqiDKRWB7YEpCIwqggysRCf1BLoCIIo4IopUxZDyBxyjruE8AZQG49yyLuQkGUkkL9
W14iEeCK8S5gtYIoZYXyQJaIBRRGBVEqs34QSyICCKOCKLXQa4pSi3mvMe402LyCKLVRFKU2ozCu
Bf6fx83eChytIEpdFEWpVdZx1wPPB3xE6i+BF2Udd7f1fks69JqiNCIfuIMYvsZ4agM3fx+wPuu4
i6z3U9KjKEqj8oE7GvggsLqGm9sGXAp8IOu4X1rvm6RJURQv8oE7DjgLOAE4cMIf/3vgOuCSrON+
Zr0vkjZFUbzKB24l8CLgD4BnAIcCq4BDgO3AltGvzcA3gC9mHfd967mlPf4J7SCZmACXY5IAAAAl
dEVYdGRhdGU6Y3JlYXRlADIwMTYtMTItMTRUMTY6NDY6MTctMDc6MDCIYmfmAAAAJXRFWHRkYXRl
Om1vZGlmeQAyMDE2LTEyLTE0VDE2OjQ2OjE4LTA3OjAwD3evswAAAABJRU5ErkJggg=="
    />
  </svg>
);

export default Git;
