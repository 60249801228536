import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { translate } from 'react-i18next';
import process from 'process';
import { setPageTitle } from '../metadata/metadataDuck';
import { withScrollToTopDecorator } from '../../components/withScrollToTop';
import { Auth } from '../../services/oauth/auth';


@connect(
  null,
  dispatch => ({
    propagatePageTitle: (...args) => dispatch(setPageTitle(...args)),
  }),
)
@translate(['resume'], { wait: process.browser })
@withScrollToTopDecorator()
export class Login extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    // Through mapDispatchToProps
    propagatePageTitle: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.auth = new Auth();
  }

  render() {
    const {
      t,
      propagatePageTitle,
    } = this.props;

    if (process.browser) {
      this.auth.login();
    }

    propagatePageTitle(t('login:pageTitle'));
    return (
      <Fragment>
        <Helmet>
          <title>
            {t('login:pageTitle')}
          </title>
          <meta name="description" content={t('login:pageDescription')} />
        </Helmet>
      </Fragment>
    );
  }
}
