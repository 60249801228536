import { combineReducers } from 'redux';

import { createLogger } from 'ms-iso-logger';


const logger = createLogger('MetadataDuck');

/*
 * Metadata DUCK
 */
/**
 * Constants
 * @type {string}
 */
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

export function setPageTitle(title) {
  return {
    type: SET_PAGE_TITLE,
    title,
  };
}

export function pageTitleReducer(
  state = '',
  { type, title },
) {
  switch (type) {
    case SET_PAGE_TITLE:
      return title;
    default:
      return state;
  }
}


// selectors
export function selectPageTitle(state) {
  if (state.metadata) {
    return state.metadata.title;
  }
  logger.error('No Reducer specified under metadata.title');
  return '';
}

/**
 * Reducer for metadata
 * @type {{newsletter: Reducer<any>}}
 */
export const metadataReducer = {
  metadata: combineReducers({
    title: pageTitleReducer,
  }),
};
