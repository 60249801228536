import React from 'react';

const Language = () => (
  <svg viewBox="0 0 100 100">
    <title />
    <path d="M31.463,81.28,5.4,50,31.463,18.72l3.073,2.561L10.6,50,34.537,78.72ZM94.6,50,68.537,18.72,65.463,21.28,89.4,50,65.463,78.72l3.074,2.561ZM61.914,17.58l-3.828-1.16-20,66,3.828,1.16Z" />
  </svg>
);

export default Language;
