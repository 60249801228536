import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../../../../node_modules/@angular/material/card/card.scss';
import './timelineContent.scss';


export class TimelineContentTemplate extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    tech: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    imageSrc: PropTypes.string.isRequired,
    youtube: PropTypes.string,
    vimeo: PropTypes.string,
  };
  static defaultProps = {
    startDate: null,
    endDate: null,
    youtube: null,
    vimeo: null,
  };

  render() {
    const {
      startDate,
      endDate,
      title,
      desc,
      tech,
      imageSrc,
      youtube,
      vimeo,
    } = this.props;
    let description = `${moment(Date.parse(startDate)).format('LL')} - ongoing`;
    if (startDate && !endDate) {
      description = `${moment(Date.parse(startDate)).format('LL')} - ongoing`;
    } else if (!startDate && endDate) {
      description = `${moment(Date.parse(endDate)).format('LL')}`;
    } else if (startDate && endDate) {
      description = `${moment(Date.parse(startDate)).format('LL')} - ${moment(Date.parse(endDate)).format('LL')}`;
    }
    const allowFullScreen = true;
    return (
      <timeline-content-container>
        <div className="timelineDate">{description}</div>
        <md-card-title>{title}</md-card-title>
        { imageSrc ? (<img md-card-image="true" src={imageSrc} alt="Project Logo" />) : ('')}
        { youtube ? (
          <div className="flex-video">
            <iframe
              id="ytplayer"
              title={title}
              type="text/html"
              width="400"
              height="250"
              src={youtube}
              frameBorder="0"
              controls="0"
            />
          </div>
        ) : ('')}
        { vimeo ? (
          <div className="flex-video">
            <iframe
              title={title}
              type="text/html"
              width="400"
              height="250"
              src={vimeo}
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen={allowFullScreen}
            />
          </div>
        ) : ('')}
        <md-card-content>{desc}</md-card-content>
        <md-card-subtitle>{tech}</md-card-subtitle>
      </timeline-content-container>
    );
  }
}
