import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import { composeWithDevTools } from 'redux-devtools-extension';
import { metadataReducer } from '../../modules/metadata/metadataDuck';

function reducerStub(store) {
  // wow, redux does some unexpected things, check it out:
  // console.log('!!!!!!!!!!!!!!!!!!!!!!!', 'store', store, 'action', action);
  // this is a workaround for its tests
  const storeIsDefined = (store !== undefined);
  return storeIsDefined ? store : null;
}

const composeEnhancers = composeWithDevTools({});

const rootReducer = combineReducers({
  form: formReducer,
  tenantId: reducerStub,
  locale: reducerStub,
  ...metadataReducer,
});

export function storeFactory(initState) {
  return createStore(
    rootReducer,
    initState,
    composeEnhancers(applyMiddleware(thunk)),
  );
}
