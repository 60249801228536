import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from '../image/Image';
import { TimelineContentTemplate } from './TimelineContentTemplate';
import TieSVG from '../../assets/icons/tie.svg';
import './timelineWrapper.scss';
import './timelineBlock.scss';
import './timelineBlockIcon.scss';

export class TimelineTemplate extends Component {
  static propTypes = {
    endPointDescription: PropTypes.string.isRequired,
    projects: PropTypes.array.isRequired,
  };
  render() {
    const {
      endPointDescription,
      projects,
    } = this.props;
    return (
      <timeline-wrapper>
        <div className="timeline-start-point" />
        { projects.map(value => (
          <timeline-block key={value.title}>
            <timeline-block-icon>
              <Image height={40} width={40} source={TieSVG} description="project icon" />
            </timeline-block-icon>
            <TimelineContentTemplate
              endDate={value.endDate}
              startDate={value.startDate}
              title={value.title}
              desc={value.desc}
              tech={value.tech}
              imageSrc={value.image}
              vimeo={value.vimeo}
              youtube={value.youtube}
            />
          </timeline-block>
        ))}
        <div className="timeline-end-Point">
          <span>{endPointDescription}</span>
        </div>
      </timeline-wrapper>
    );
  }
}
