import React from 'react';

const Skrum = () => (
  <svg
    enableBackground="new 0 0 128 128"
    id="Layer_1"
    version={1.1}
    viewBox="0 0 128 128"
    xmlSpace="preserve"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M117,89c0-1.1-0.9-2-2-2H93c-1.1,0-2,0.9-2,2v22c0,1.1,0.9,2,2,2h22c1.1,0,2-0.9,2-2V89z"
      fill="#FFFFFF"
    />
    <path
      d="M115,87H93c-1.1,0-2,0.9-2,2  v22c0,1.1,0.9,2,2,2h22c1.1,0,2-0.9,2-2V96"
      fill="none"
      stroke="#4A7DA7"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
    />
    <path
      d="M88,21.7  c5.7-5.7,14.8-5.7,20.5,0c5.7,5.7,5.7,14.8,0,20.5c-5.7,5.7-15.3,6-21,0.3"
      fill="none"
      stroke="#F3B0AE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={4}
    />
    <path
      d="M37,89c0-1.1-0.9-2-2-2H13c-1.1,0-2,0.9-2,2v22c0,1.1,0.9,2,2,2h22c1.1,0,2-0.9,2-2V89z"
      fill="#D8E3EA"
    />
    <path
      d="  M54,100c21.3,0,38.5-17.2,38.5-38.5S75.3,23,54,23c-10.8,0-20.6,4.5-27.6,11.7"
      fill="none"
      stroke="#4A7DA7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={4}
    />
    <line
      fill="none"
      stroke="#F3B0AE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={4}
      x1={88}
      x2={93.2}
      y1={21.7}
      y2={21.7}
    />
    <line
      fill="none"
      stroke="#F3B0AE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={4}
      x1={88}
      x2={88}
      y1={21.7}
      y2={16.9}
    />
    <path
      d="M37,89c0-1.1-0.9-2-2-2H13  c-1.1,0-2,0.9-2,2v22c0,1.1,0.9,2,2,2h22c1.1,0,2-0.9,2-2V89z"
      fill="none"
      stroke="#4A7DA7"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
    />
    <polyline
      fill="none"
      points="97.1,98.3   102.9,104.1 116.5,90.5 "
      stroke="#B2DDC9"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={4}
    />
    <line
      fill="none"
      stroke="#B2DDC9"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={4}
      x1={43}
      x2={85}
      y1={100}
      y2={100}
    />
    <line
      fill="none"
      stroke="#4A7DA7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={4}
      x1={26.4}
      x2={32.4}
      y1={34.7}
      y2={34.7}
    />
    <line
      fill="none"
      stroke="#4A7DA7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={4}
      x1={26.4}
      x2={26.4}
      y1={34.7}
      y2={29.2}
    />
    <line
      fill="none"
      stroke="#B2DDC9"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={4}
      x1={85}
      x2={81.4}
      y1={100}
      y2={96}
    />
    <line
      fill="none"
      stroke="#B2DDC9"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={4}
      x1={85}
      x2={81.4}
      y1={100}
      y2={103.6}
    />
  </svg>
);

export default Skrum;
