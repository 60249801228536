import deDE from './de-DE/resume.i18n.json';
import en from './en/resume.i18n.json';

export const resumeLocales = {
  'de-DE': {
    resume: deDE,
  },
  en: {
    resume: en,
  },
};
