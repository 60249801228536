import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './roundImage.scss';

export class Image extends Component {
  static propTypes = {
    isRound: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    source: PropTypes.string.isRequired,
    sourceSet: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string.isRequired,
  };

  static defaultProps = {
    sourceSet: [],
    isRound: false,
    height: null,
    width: null,
  };

  render() {
    const {
      isRound,
      height,
      width,
      source,
      sourceSet,
      description,
    } = this.props;
    const srcSet = sourceSet.reduce((accumulator, currentValue, currentIndex) => {
      if (currentIndex === 0) {
        return `${currentValue}`;
      }
      return `${accumulator}, ${currentValue} ${currentIndex}x`;
    }, '');
    const roundImage = isRound ? 'roundImage image' : 'image';
    return (
      <img
        className={
          roundImage
        }
        src={source}
        srcSet={srcSet}
        alt={description}
        width={width}
        height={height}
      />
    );
  }
}
