import deDE from './de-DE/linkRelation.i18n.json';
import en from './en/linkRelation.i18n.json';

export const linkRelationLocales = {
  'de-DE': {
    linkRelation: deDE,
  },
  en: {
    linkRelation: en,
  },
};
