import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { AppContainer } from '../../modules/app/AppContainer';

import { Landing } from '../../modules/landing/Landing';
import { Resume } from '../../modules/resume/Resume';
import { TechStack } from '../../modules/techStack/TechStack';
import { Imprint } from '../../modules/imprint/Imprint';
import { Login } from '../../modules/login/Login';
import { NotFound } from '../../modules/notFound/notFound';


const Page = ({ route }) => (
  <AppContainer>
    {renderRoutes(route.routes)}
  </AppContainer>
);
Page.propTypes = {
  route: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const routes = [
  {
    component: withRouter(Page),
    init: './init',
    routes: [
      {
        path: '/',
        exact: true,
        component: Landing,
      },
      {
        path: '/resume',
        exact: true,
        component: Resume,
      },
      {
        path: '/techStack',
        exact: true,
        component: TechStack,
      },
      {
        path: '/imprint',
        exact: true,
        component: Imprint,
      },
      {
        path: '/login',
        exact: true,
        component: Login,
      },
      {
        component: NotFound,
      },
    ],
  },
];

export { routes };
