import Projects from '../../assets/icons/projects.png';
import Technology from '../../assets/icons/technology.png';
import Xing from '../../assets/icons/xing.png';
import Contact from '../../assets/icons/contact.png';
import LinkedIn from '../../assets/icons/linkedin.png';

const linkRelations = [
  {
    id: 'home',
    navigationBar: true,
    landingPage: false,
    isActiveEnabled: false,
    routerLink: '/',
    href: '',
    title: 'linkRelation:home.title',
    icon: null,
    description: 'linkRelation:home.description',
  },
  {
    id: 'projects',
    navigationBar: true,
    landingPage: true,
    isActiveEnabled: true,
    routerLink: '/resume',
    href: '',
    title: 'linkRelation:projects.title',
    icon: Projects,
    description: 'linkRelation:projects.description',
  },
  {
    id: 'techStack',
    navigationBar: true,
    landingPage: true,
    isActiveEnabled: true,
    routerLink: '/techStack',
    href: '',
    title: 'linkRelation:techStack.title',
    icon: Technology,
    description: 'linkRelation:techStack.description',
  },
  {
    id: 'contact',
    navigationBar: false,
    landingPage: true,
    routerLink: null,
    isActiveEnabled: true,
    href: 'mailto: manuel@scolp.de',
    title: 'linkRelation:contact.title',
    icon: Contact,
    description: 'linkRelation:contact.description',
  },
  {
    id: 'xing',
    navigationBar: false,
    landingPage: true,
    routerLink: null,
    href: 'https://www.xing.com/profile/Manuel_Spoerer2',
    title: 'linkRelation:xing.title',
    icon: Xing,
    description: 'linkRelation:xing.description',
  },
  {
    id: 'linkedIn',
    navigationBar: false,
    landingPage: true,
    routerLink: '',
    href: 'https://www.linkedin.com/in/manuel-spoerer-9b1218130',
    title: 'linkRelation:linkedIn.title',
    icon: LinkedIn,
    description: 'linkRelation:linkedIn.description',
  },
];

export const getAllLinkRelations = () => linkRelations;

export const getAllLinkRelationsForLandindPage = () =>
  linkRelations.filter(item => item.landingPage);

export const getLinkRelationsForNavBar = () => linkRelations.filter(item => item.navigationBar);

