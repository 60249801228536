import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from '../card/Card';
import './linkCard.scss';

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.linksTo && !props.href) {
    return new Error(`One of props 'linksTo' or 'href' was not specified in '${componentName}'.`);
  }
  if (!props.icon && !props.imageUrl) {
    return new Error(`One of props 'icon' or 'imageUrl' was not specified in '${componentName}'.`);
  }
  return null;
};

export class LinkCard extends Component {
  static propTypes = {
    icon: requiredPropsCheck,
    imageUrl: requiredPropsCheck,
    title: PropTypes.string.isRequired,
    linksTo: requiredPropsCheck,
    href: requiredPropsCheck,
    linkDescription: PropTypes.string.isRequired,
  };

  static defaultProps = {
    icon: null,
    imageUrl: null,
    linksTo: null,
    href: null,
  };

  render() {
    const {
      icon,
      imageUrl,
      href,
      title,
      linksTo,
      linkDescription,
    } = this.props;

    const image = () => (<Fragment><img src={imageUrl} alt="Icon of link" /></Fragment>);
    const Icon = icon || image;

    const RouterBasedLinkCard = (
      <Link to={linksTo} className="link-card" >
        <Card>
          <Fragment>
            <div className="card-header">
              <Icon />
              <div className="card-title">{title}</div>
            </div>
            <p className="card-desc" dangerouslySetInnerHTML={{ __html: linkDescription }} />
          </Fragment>
        </Card>
      </Link>
    );

    const LinkBasedLinkCard = (
      <a href={href} className="link-card" >
        <Card>
          <Fragment>
            <div className="card-header">
              <Icon />
              <div className="card-title">{title}</div>
            </div>
            <p className="card-desc" dangerouslySetInnerHTML={{ __html: linkDescription }} />
          </Fragment>
        </Card>
      </a>
    );
    return (
      <Fragment>
        { href ? (LinkBasedLinkCard) : (RouterBasedLinkCard) }
      </Fragment>
    );
  }
}
