import deDE from './de-DE/landing.i18n.json';
import en from './en/landing.i18n.json';

export const landingLocales = {
  'de-DE': {
    landing: deDE,
  },
  en: {
    landing: en,
  },
};
