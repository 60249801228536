import deDE from './de-DE/footer.i18n.json';
import en from './en/footer.i18n.json';

export const footerLocales = {
  'de-DE': {
    footer: deDE,
  },
  en: {
    footer: en,
  },
};
