import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Helmet } from 'react-helmet';
import process from 'process';
import { connect } from 'react-redux';
import { setPageTitle } from '../metadata/metadataDuck';
import { getTechStack } from '../../services/techStack/techStack';
import { TechStackTemplate } from './TechStackTemplate';
import { withScrollToTopDecorator } from '../../components/withScrollToTop';

@connect(
  null,
  dispatch => ({
    propagatePageTitle: (...args) => dispatch(setPageTitle(...args)),
  }),
)
@translate(['techstack'], { wait: process.browser })
@withScrollToTopDecorator()
export class TechStack extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    // Through mapDispatchToProps
    propagatePageTitle: PropTypes.func.isRequired,
  };
  render() {
    const {
      t,
      propagatePageTitle,
    } = this.props;
    propagatePageTitle(t('techstack:pageTitle'));
    const stacks = getTechStack();
    return (
      <Fragment>
        <Helmet>
          <title>
            {t('techstack:pageTitle')}
          </title>
          <meta name="description" content={t('techstack:pageDescription')} />
        </Helmet>
        <div className="techstack-content-wrapper">
          {
            /*
              <div
                className="page-intro"
                dangerouslySetInnerHTML={{ __html: t('techstack:introduction') }}
              />
            */
          }
          <TechStackTemplate techstack={stacks} t={t} />
        </div>
      </Fragment>
    );
  }
}
