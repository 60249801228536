import React from 'react';

const Angular1 = () => (
  <svg
    width="378px"
    height="400px"
    viewBox="0 0 378 400"
    version={1.1}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>angular-1</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="angular-1">
        <g id="Layer_1">
          <g id="AngularJS_Logo">
            <g id="Group">
              <polygon
                id="Shape"
                fill="#B2B2B2"
                points="187.986104 0 0 66.151861 29.7042184 312.429777 188.175682 399.530521 347.466998 311.254591 377.163275 64.9826303"
              />
              <polygon
                id="Shape"
                fill="#B52E31"
                points="358.419851 79.2168734 187.546402 20.9449132 187.546402 378.728536 330.74938 299.476923"
              />
              <polygon
                id="Shape"
                fill="#E23237"
                points="21.1007444 80.266005 46.5568238 300.531017 187.545409 378.721588 187.545409 20.9369727"
              />
              <path
                d="M227.156328,198.902233 L187.858065,116.839702 L153.263524,198.901241 L227.156328,198.902233 L227.156328,198.902233 Z M242.136973,233.405459 L137.977171,233.405459 L114.672953,291.69727 L71.3280397,292.498263 L187.547395,33.937469 L307.824318,292.492308 L267.649628,292.492308 L242.136973,233.405459 Z"
                id="Shape"
                fill="#F2F2F2"
              />
              <polygon
                id="Shape"
                fill="#B2B2B2"
                points="187.546402 33.9384615 187.857072 116.839702 227.111663 198.964764 187.635732 198.964764 187.546402 233.353846 242.133995 233.406452 267.649628 292.502233 309.126551 293.272457"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Angular1;
