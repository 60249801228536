import React, { Component } from 'react';
import { createLogger } from 'ms-iso-logger';
import process from 'process';

const logger = createLogger('withScrollToTopHoc');

/**
 * HOC
 * Lets pages scroll to top after an transition accured.
 * @param ReactComponent
 * @returns {{new(): GetConfig, prototype: GetConfig}}
 */
function withScrollToTopHoc(ReactComponent) {
  return class GetScrollToTop extends Component {
    componentWillMount() {
      if (process.browser) {
        logger.debug('scroll to top');
        window.scrollTo(0, 0);
      }
    }
    render() {
      return (<ReactComponent {...this.props} />);
    }
  };
}

/**
 * HOC Decorator
 * @returns {function(*): {propTypes, new(): GetScrollToTop, prototype: GetScrollToTop}}
 */
export function withScrollToTopDecorator() {
  return withScrollToTopHoc;
}
