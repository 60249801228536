import React from 'react';

const Tdd = () => (
  <svg
    id="master-artboard"
    viewBox="0 0 354.71075439453125 359.57025146484375"
    version={1.1}
    x="0px"
    y="0px"
    style={{ enableBackground: 'new 0 0 1400 980' }}
    width="354.71075439453125px"
    height="359.57025146484375px"
  >
    <rect
      id="ee-background"
      x={0}
      y={0}
      width={354.71075439453125}
      height={359.57025146484375}
      style={{ fill: 'white', fillOpacity: 0, pointerEvents: 'none' }}
    />
    <defs />
    <g transform="matrix(1, 0, 0, 1, -101.15702056884766, -74.18180680274963)">
      <g>
        <g>
          <path
            fill="#09B777"
            d="M162.9,196.4l-57.8,57.8h27.3c0,80.5,65.5,145.9,145.9,145.9v-60.9c-46.8,0-85-38.1-85-85h27.3L162.9,196.4z"
          />
          <path
            fill="#0069AA"
            d="M222.5,367.9l57.8,57.8v-27.3c80.5,0,145.9-65.5,145.9-145.9h-60.9c0,46.8-38.1,85-85,85v-27.3L222.5,367.9z"
          />
          <path
            fill="#E34856"
            d="M394.1,308.3l57.8-57.8h-27.3c0-80.5-65.5-145.9-145.9-145.9v60.9c46.8,0,85,38.1,85,85h-27.3L394.1,308.3z"
          />
          <path
            fill="#FF8C00"
            d="M162.9,196.4l32.2,32.2c10.3-35.4,43-61.4,81.6-61.4v27.3l57.8-57.8l-57.8-57.8v27.3c-71.6,0-131.3,51.9-143.6,120L162.9,196.4z"
          />
        </g>
        <path d="M 334.3999938964844 136.6999969482422 L 311.6000061035156 172.1999969482422 L 302.29998779296875 168.89999389648438 Z" />
        <path d="M 162.89999389648438 196.39999389648438 L 198.3000030517578 219.1999969482422 L 195 228.5 Z" />
        <path d="M 222.5 367.8999938964844 L 245.3000030517578 332.5 L 254.6999969482422 335.79998779296875 Z" />
        <path d="M 394.1000061035156 308.29998779296875 L 358.6000061035156 285.5 L 361.8999938964844 276.1000061035156 Z" />
      </g>
    </g>
  </svg>
);

export default Tdd;
