import React from 'react';

const TestCafe = () => (
  <svg
    version={1.1}
    id="Layer_1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="190px"
    height="122px"
    viewBox="0 0 190 122"
    enableBackground="new 0 0 190 122"
    xmlSpace="preserve"
  >
    <polygon
      fill="#36B6E5"
      points="175.888,18.187 159.723,1.97 91.827,70.095 65.962,44.143 49.8,60.363 91.827,102.535 "
    />
    <polygon
      fill="#404142"
      points="117.697,83 109.697,90 137.697,90 161.697,112 27.697,112 51.697,90 72.697,90 65.697,83 46.697,83
1,122 188.514,122 142.697,83 "
    />
  </svg>
);

export default TestCafe;
