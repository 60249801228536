import React from 'react';

const Html5 = () => (
  <svg
    width="298px"
    height="420px"
    viewBox="0 0 298 420"
    version={1.1}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Slice 1</title>
    <description>
      {'Created with Sketch (http://www.bohemiancoding.com/sketch)'}
    </description>
    <defs />
    <g id="Page 1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Group">
        <path
          d="M27.383,386.266 L0.313,82.646 L297.763,82.646 L270.665,386.217 L148.856,419.986 L27.383,386.266 Z M27.383,386.266"
          id="Shape"
          fill="#E44F26"
        />
        <path
          d="M149.038,394.175 L247.465,366.887 L270.623,107.471 L149.038,107.471 L149.038,394.175 Z M149.038,394.175"
          id="Shape"
          fill="#F1662A"
        />
        <path
          d="M149.038,220.08 L99.763,220.08 L96.359,181.948 L149.038,181.948 L149.038,144.709 L148.909,144.709 L55.663,144.709 L56.555,154.7 L65.707,257.316 L149.038,257.316 L149.038,220.08 Z M149.038,220.08"
          id="Shape"
          fill="#EBEBEB"
        />
        <path
          d="M149.038,316.788 L148.875,316.834 L107.403,305.635 L104.751,275.937 L84.6,275.937 L67.371,275.937 L72.588,334.404 L148.866,355.58 L149.038,355.532 L149.038,316.788 Z M149.038,316.788"
          id="Shape"
          fill="#EBEBEB"
        />
        <path
          d="M27.988,0.135 L46.912,0.135 L46.912,18.831 L64.223,18.831 L64.223,0.135 L83.148,0.135 L83.148,56.753 L64.224,56.753 L64.224,37.795 L46.914,37.795 L46.914,56.753 L27.988,56.753 L27.988,0.135 L27.988,0.135 Z M27.988,0.135"
          id="Shape"
          fill="#010101"
        />
        <path
          d="M108.032,18.91 L91.372,18.91 L91.372,0.135 L143.624,0.135 L143.624,18.91 L126.957,18.91 L126.957,56.753 L108.033,56.753 L108.033,18.91 L108.032,18.91 L108.032,18.91 Z M108.032,18.91"
          id="Shape"
          fill="#010101"
        />
        <path
          d="M151.917,0.135 L171.65,0.135 L183.787,20.029 L195.914,0.135 L215.653,0.135 L215.653,56.753 L196.807,56.753 L196.807,28.69 L183.786,48.82 L183.461,48.82 L170.433,28.69 L170.433,56.753 L151.916,56.753 L151.916,0.135 L151.917,0.135 Z M151.917,0.135"
          id="Shape"
          fill="#010101"
        />
        <path
          d="M225.071,0.135 L244,0.135 L244,38.038 L270.612,38.038 L270.612,56.752 L225.07,56.752 L225.07,0.135 L225.071,0.135 Z M225.071,0.135"
          id="Shape"
          fill="#010101"
        />
        <path
          d="M148.909,220.08 L148.909,257.316 L194.763,257.316 L190.441,305.61 L148.909,316.82 L148.909,355.562 L225.249,334.404 L225.808,328.114 L234.559,230.079 L235.467,220.08 L225.433,220.08 L148.909,220.08 Z M148.909,220.08"
          id="Shape"
          fill="#FFFFFF"
        />
        <path
          d="M148.909,144.709 L148.909,167.837 L148.909,181.856 L148.909,181.948 L238.736,181.948 L238.856,181.948 L239.603,173.578 L241.299,154.7 L242.19,144.709 L148.909,144.709 Z M148.909,144.709"
          id="Shape"
          fill="#FFFFFF"
        />
      </g>
    </g>
  </svg>
);

export default Html5;
