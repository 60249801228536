import React from 'react';

const Framework = () => (
  <svg viewBox="0 0 100 100">
    <title />
    <path d="M77,64V48H27V36H43V10H7V36H23V64H7V90H43V64H27V52H73V64H57V90H93V64ZM11,14H39V32H11ZM39,86H11V68H39Zm50,0H61V68H89Z" />
  </svg>
);

export default Framework;
