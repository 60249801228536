import React from 'react';

const Kanban = () => (
  <svg
    enableBackground="new 0 0 500 500"
    height="500px"
    id="Layer_1"
    version={1.1}
    viewBox="0 0 500 500"
    width="500px"
    xmlSpace="preserve"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M112,101H27c-5.522,0-10-4.478-10-10s4.478-10,10-10h85c5.522,0,10,4.478,10,10S117.522,101,112,101z"
        fill="#333333"
      />
      <path
        d="M233,101h-86c-5.522,0-10-4.478-10-10s4.478-10,10-10h86c5.522,0,10,4.478,10,10S238.522,101,233,101z"
        fill="#FFC200"
      />
      <path
        d="M353,101h-86c-5.522,0-10-4.478-10-10s4.478-10,10-10h86c5.522,0,10,4.478,10,10S358.522,101,353,101z"
        fill="#F1515B"
      />
      <path
        d="M473,101h-85c-5.522,0-10-4.478-10-10s4.478-10,10-10h85c5.522,0,10,4.478,10,10S478.522,101,473,101z"
        fill="#7BB54D"
      />
      <path
        d="M223,150v40h-65v-40H223 M233,130h-85c-5.523,0-10,4.478-10,10v60c0,5.522,4.477,10,10,10h85   c5.523,0,10-4.478,10-10v-60C243,134.478,238.523,130,233,130L233,130z"
        fill="#FFC200"
      />
      <path
        d="M102,150v40H37v-40H102 M112,130H27c-5.523,0-10,4.478-10,10v60c0,5.522,4.477,10,10,10h85   c5.523,0,10-4.478,10-10v-60C122,134.478,117.523,130,112,130L112,130z"
        fill="#333333"
      />
      <path
        d="M343,150v40h-65v-40H343 M353.334,130h-85c-5.523,0-10.334,4.478-10.334,10v60c0,5.522,4.811,10,10.334,10   h85c5.523,0,9.666-4.478,9.666-10v-60c0-5.522-4.31-10-9.833-10H353.334z"
        fill="#F1515B"
      />
      <path
        d="M464,258v40h-65v-40H464 M474,238h-85c-5.523,0-10,4.478-10,10v60c0,5.522,4.477,10,10,10h85   c5.523,0,10-4.478,10-10v-60C484,242.478,479.523,238,474,238L474,238z"
        fill="#7BB54D"
      />
      <path
        d="M102,258v40H37v-40H102 M112,238H27c-5.523,0-10,4.478-10,10v60c0,5.522,4.477,10,10,10h85   c5.523,0,10-4.478,10-10v-60C122,242.478,117.523,238,112,238L112,238z"
        fill="#333333"
      />
      <path
        d="M102,367v40H37v-40H102 M112,347H27c-5.523,0-10,4.478-10,10v60c0,5.522,4.477,10,10,10h85   c5.523,0,10-4.478,10-10v-60C122,351.478,117.523,347,112,347L112,347z"
        fill="#333333"
      />
      <path
        d="M464,150v40h-65v-40H464 M474,130h-85c-5.523,0-10,4.478-10,10v60c0,5.522,4.477,10,10,10h85   c5.523,0,10-4.478,10-10v-60C484,134.478,479.523,130,474,130L474,130z"
        fill="#7BB54D"
      />
    </g>
  </svg>
);

export default Kanban;
