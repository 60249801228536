import React from 'react';

const Ecma6 = () => (
  <svg
    version={1.1}
    id="Layer_1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="225px"
    height="225px"
    viewBox="0 0 225 225"
    enableBackground="new 0 0 225 225"
    xmlSpace="preserve"
  >
    <image
      id="image0"
      width={225}
      height={225}
      x={0}
      y={0}
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAApVBMVEXyZSLxYR3xXRXxXAzx
WAnxVwDyYh7zczb0hlj0lHD2on72p4b4sZL4tJb1m3n1k2f0il70glHzd0P1kGTybi7yairycj32
rpf5y7760MT86OL97en//fz////+9/b73tb61cf4xLP5vaX2oILzeELyay371sP3tJ774dr++fn4
wa/+8+z97+f708L2povzfUr6y7XwUgD0kGz2qI71qZD4wbH84tUTLr8jAAAAAWJLR0Qd6wNxkQAA
AAd0SU1FB+AMDg8xADK+a58AAAf2SURBVHja7ZvreqI6FEBJ0IjaIoigAg2IUgEvtNbp+z/aAW9N
FCQ6pXW+s9efmbZGstiQ7NwkCQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAD+vyCM5UajeaTRaMg4g/x2vVhwQ4zLWhMs45aitDud7tPzjm5H7fQ0Re9LckPE
kxC0o9YbQnRDDH3A14Mg09Lbw9HYdhz35QB1HM8fT4JpT9NnVlhW80wsjzSWwkFOKO1+qseUyK8v
rhBjDfO3v2XMo/jkxpF9nHrJUMGFlyREMs3craWo0x3aztM0awlhZigGb4iQNlrQa593Y6cjF10x
e7b16XDkR8uls6A5i8VyafujYKjX4HifISHWcxRXlaDdxsX1sGypwcpznMvSMXUMfEvdazVszZ3q
ElH7PIYI9dXkyq3pNR/EEJtTKlDCPwsJQS0juHpneo2bBeoxlBWBCJ6/ubng2r5awDUexBCniVCJ
oI/YS8mzoCLytiLfoVCDYVMVCiFvSFCaVD3akf4YhkQKzv9Goz22QxmLrsn04ESaV7670e/G0D8Z
6mP29669SubqnulTELyt/Chy8r7SeP+6DiKbqPISnl57b+EuyhkdMhSCVLaurteWZIx2CSbZ5WKy
qWzW80m0YNsN3PLP0x83zvv6A1m/79JJWruhT8xBGcd0g+An9jX0Zpjw7LJLM/si5jLIVBdnfvZo
Pp2m++uZLXX6ESRbC92hcJPh63sDl0KOhuwLRefS5X0n+8yaeQux9Yfr5+lEVVJrMEDHLw8HrdnM
MmtIvc8Mm9WXIPiNed5sQxK5743UZh9Sb5tKu8HE6QPo/J7UZlhdXSSvmBKfAyJSLWxwbaZKfm6M
fLsh4QxfZZEQov6cKRN/hDW8b79riJUR08b4dXQK32mIbzeUex7TynwMHtzwjhjKbSblXig/+Iz+
fUvjC5TImtIO08HYrZ8M4V8/pT4WMWw+sUUe3/CNbfg1SaDdZw3d8eMbBkx64nxIAvVlDeNfNhTJ
aZ6Zlyr2e6RReVseKIbvpVkpY8hl3i9+28rSS3Q1tXkcQ18atIr5GihkoyePNXxxxus0H3lckbw0
3I9CdkMuIpT3fZNhbC+LsSdfvTRSuBFwPtFp+3O1j8sDeW5ICGKfD1Sno+gY3/8yJGR+8Wd3Yftv
z1MtbMpFOTXf0khNS1O38+BtlfMWbKcbS5brkhQ0dMdMplU2E0XtcdDt6Wbzoo/kDL1uNxlHDj22
yNSx/aSjhTU53mOI0sAt/aQ96uiz8GwZiXtKadGMlOt1rXoW2e4xlJB2ZVbJdZ3RdCZxiQCX05RA
J7NaXsf7DMPrM6bxwvvos++jiOFLvKpjmuY+Q4mEG/96AWfUZvpQIcOXOKijp7zPUEJYCyqW1zzV
PBURM3yhqkiS+zOGEmmE7eDTuSZpG6f3StDwZVLD6P+O/vBYEhF9PbIpdavrK2roqN8/rX9mWLqK
XzCoyyIUhqkaeGWOtHtc8CwxdC8KBt/fY/CGn5rRK8TQi96QLPdC4UxX1GBc+FKOUlJm6PijP0Ew
H3l85zjq12z4+v7eLKb06UFYbuK+0us8n9c2a2zah5DwhtRLnlRNSS3L0o3umJsq3nx7EM/GFgLj
w1LNtD33+W7S7uICQydoD3arB/k8dwNrEaMYqd8+VXz7GL/8q4hsrT02IjQoMIz5ZhmZQ+a2RNNH
NsxbV1PxbjQk0sYuKPGYhlK+S2PIrqK9FRiej/GJ5HOG3525fbMhkbik/PVQ32uGqLn6l2KYZaw+
Z1gdQ9R8/acMufoKGv5bMbzH8N+KIQHD/8FTGlcYPnhvISH++wp6i7jiKX1wQyKlbFKzOhp2mV96
ZwOx1ufX35ztQzylJN+IX1wRJKlMDhYnh49xK6TLfnnWVnteunpvlp9GOMqTVrurpaSRz8affxs2
J8xAkc4P9eVWuanKLshhwm5ZPI236jL8VLTq0whI82P/j6ros5aJ9qcI9mAszbhpxtMzhw3m2XV9
g+D9ikxWRtLYXbVjpeYR8JWDCafdl4edey71JtvNzMzZf1X2r8JvIfXUQ0S43Sb59uiTh7nhxltJ
WLfhFU6Gx/VD16V0sfRXo2A7HA63w2T1ueDnMk4RQdaWu4+L8XbdNjabj7nPbeij3dpnokQMZX7V
wo2ps4NeTNV8zSthg590ih3HjvJtqPyvvd5DGJorwRKOejpc0JhFrkCJeQ3z+nfsgtY9wRJJeqov
CtcC5xds7RFOlPA93tUQbpiAYNOvLEDXg9p30AoZihyXyVhM2R2ISDKqQk+TOgRvN8TmWOjjzvrs
NJ/Zvq5Ik1ltp/NujGF3bFce6or9LIJ8fZHUHl8pFw3Tepa57zlRonSTT/vashONEuVyjICQnkTF
7U3srNSwpl02BL8tliIsJsfzkgRhOWxvJ9GSUhpzorsDBkt/mOLC6mKsDP0FpeyKTJ42RBMV17YX
QyJle4TK9wztT4EOBpY6DCZjL3L2eZ0TRd4oGbbz7UPFK/K7Yuk6GHmRHedFYjvykqHWCs06d9Rg
Ubg67Lb8hNZspiuKtl+e0hRFT/uWiXB5i5GvVrVmaVZot8hlZEVmVlaFH91TewP7IzKyvB9eZf/B
GFe3h2hX6FhGpAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAA/Df3tMmlmyF7exAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE2LTEyLTE0VDE1OjQ5OjAwLTA3
OjAwza5utQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNi0xMi0xNFQxNTo0OTowMC0wNzowMLzz1gkA
AAAASUVORK5CYII="
    />
  </svg>
);

export default Ecma6;
