import React from 'react';

const Eclipse = () => (
  <svg
    width="256px"
    height="240px"
    viewBox="0 0 256 240"
    version={1.1}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid"
  >
    <defs>
      <linearGradient
        x1="49.9996994%"
        y1="1089.3962%"
        x2="49.9996994%"
        y2="-159.499661%"
        id="linearGradient-1"
      >
        <stop stopColor="#473788" offset="30.33%" />
        <stop stopColor="#2C2255" offset="87.2%" />
      </linearGradient>
      <linearGradient
        x1="49.9989479%"
        y1="896.536635%"
        x2="49.9989479%"
        y2="-352.359227%"
        id="linearGradient-2"
      >
        <stop stopColor="#473788" offset="30.33%" />
        <stop stopColor="#2C2255" offset="87.2%" />
      </linearGradient>
      <linearGradient
        x1="49.9988435%"
        y1="215.169856%"
        x2="49.9988435%"
        y2="-166.715419%"
        id="linearGradient-3"
      >
        <stop stopColor="#473788" offset="30.33%" />
        <stop stopColor="#2C2255" offset="86.31%" />
      </linearGradient>
    </defs>
    <g>
      <g>
        <path
          d="M70.3816921,153.048902 L31.2610225,153.048902 C36.4408197,170.817218 46.0527663,186.742116 60.1248993,200.816585 C82.5590052,223.253028 109.535071,234.456062 141.081135,234.456062 C147.387076,234.456062 153.49676,233.986446 159.431215,233.091605 C183.192395,229.505232 203.995695,218.762468 221.820085,200.816585 C235.983337,186.746789 245.663039,170.817218 250.882555,153.048902 L235.006722,153.048902 L211.792259,153.048902 L70.3816921,153.048902 L70.3816921,153.048902 Z"
          fill="#2C2255"
        />
        <path
          d="M48.8097175,99.9145092 L28.3732329,99.9145092 C27.6255852,104.405069 27.1162501,108.989084 26.8849466,113.689919 L51.1741536,113.689919 L63.3584759,113.689919 L239.230932,113.689919 L255.284332,113.689919 C255.050692,108.989084 254.541357,104.405069 253.7867,99.9145092"
          fill="#2C2255"
        />
        <path
          d="M26.8849466,126.481706 C27.1162501,131.184877 27.6232488,135.768893 28.3732329,140.257116 L49.6204481,140.257116 L65.7252483,140.257116 L237.796383,140.257116 L253.791373,140.257116 C254.543693,135.768893 255.057701,131.184877 255.291341,126.481706"
          fill="#2C2255"
        />
        <path
          d="M250.887228,87.1203867 C245.672385,69.3030058 235.990346,53.3033435 221.820085,39.1307454 C204.042423,21.3530832 183.297533,10.6874205 159.613454,7.09871116 C153.622926,6.18985184 147.452495,5.71322638 141.081135,5.71322638 C109.535071,5.71322638 82.5566688,16.8555146 60.1248993,39.1307454 C46.0480935,53.3033435 36.4314741,69.3030058 31.2540133,87.1203867"
          fill="#2C2255"
        />
        <path
          d="M20.0930339,120.089317 C20.0930339,59.7541419 65.407498,9.62202239 124.357188,0.80445148 C122.894602,0.750714296 121.425007,0.692304314 119.948403,0.692304314 C53.7955936,0.692304314 0.16354795,54.14912 0.16354795,120.089317 C0.16354795,186.031851 53.7932572,239.48633 119.948403,239.48633 C121.42968,239.48633 122.899275,239.430256 124.366534,239.376519 C65.407498,230.558948 20.0930339,180.426829 20.0930339,120.089317 L20.0930339,120.089317 Z"
          fill="#F7941E"
        />
      </g>
      <g transform="translate(63.082781, 99.461248)">
        <path
          d="M155.728022,14.2263353 C155.344852,9.5044723 154.550476,4.8994293 153.363586,0.450925062 L2.64246759,0.450925062 C1.45557676,4.89709291 0.658864599,9.5021359 0.275695116,14.2263353 L155.728022,14.2263353 L155.728022,14.2263353 Z"
          fill="url(#linearGradient-1)"
        />
        <path
          d="M155.728022,27.0204578 L0.278031515,27.0204578 C0.661200998,31.7423207 1.45324036,36.3473637 2.64246759,40.795868 L153.365922,40.795868 C154.552813,36.3473637 155.344852,31.7423207 155.728022,27.0204578 L155.728022,27.0204578 Z"
          fill="url(#linearGradient-2)"
        />
        <path
          d="M78.0030266,98.6357687 C109.303768,98.6357687 136.284507,80.1945691 148.709478,53.587654 L7.29657497,53.587654 C19.7215464,80.1945691 46.7022853,98.6357687 78.0030266,98.6357687 L78.0030266,98.6357687 Z"
          fill="url(#linearGradient-3)"
        />
      </g>
      <g transform="translate(25.700392, 86.446774)" fill="#FFFFFF">
        <path d="M26.1396352,40.0349319 L37.5506093,40.0349319 L193.40246,40.0349319 L213.876327,40.0349319 L229.429737,40.0349319 C229.537212,37.9999281 229.597958,35.950906 229.597958,33.8878654 C229.597958,31.6566041 229.516184,29.4440339 229.390018,27.2408094 L213.873991,27.2408094 L193.400124,27.2408094 L37.5482729,27.2408094 L25.3312411,27.2408094 L1.18455444,27.2408094 C1.05838888,29.4416975 0.976614902,31.6566041 0.976614902,33.8878654 C0.976614902,35.950906 1.03736128,37.9999281 1.14483565,40.0349319 L26.1396352,40.0349319 L26.1396352,40.0349319 Z" />
        <path d="M44.2233657,0.673613119 L5.55128471,0.673613119 C4.28028349,4.83941305 3.46020734,9.11268734 2.67284078,13.4677356 L22.5906447,13.4677356 L39.5505671,13.4677356 L190.666537,13.4677356 L212.067954,13.4677356 L227.36436,13.4677356 C226.574657,9.11502374 225.558324,4.84174945 224.289659,0.673613119" />
        <path d="M212.673081,53.8080057 L191.271664,53.8080057 L40.1580309,53.8080057 L24.0088391,53.8080057 L2.67050439,53.8080057 C3.43684335,58.1607176 4.30832029,62.4316555 5.5582939,66.6021282 L44.8261567,66.6021282 L186.603538,66.6021282 L209.878748,66.6021282 L225.037307,66.6021282 C226.284944,62.4339919 227.291932,58.163054 228.060607,53.8080057 L212.673081,53.8080057 L212.673081,53.8080057 Z" />
      </g>
    </g>
  </svg>
);

export default Eclipse;
