import React from 'react';

const Agile = () => (
  <svg
    enableBackground="new 0 0 100 100"
    id="Layer_1"
    version={1.1}
    viewBox="0 0 100 100"
    xmlSpace="preserve"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M50,9C27.4,9,9,27.4,9,50s18.4,41,41,41s41-18.4,41-41S72.6,9,50,9z M63.7,77.5v-3.9h-17c-0.1,0-0.2,0-0.3,0   H34.9v-1.5h14.4h0.5c8-1.6,14.1-8.6,14.1-17.1c0-9.6-7.8-17.4-17.4-17.4c-5.2,0-9.7,2.2-12.9,5.7l3.7,2.2l-8,3.9L29,40.6l3.5,2   c1.5-1.7,3.2-3.1,5.2-4.2c-3.6-0.8-6.3-4.1-6.3-7.9c0-4.4,3.6-8.1,8.1-8.1c2.4,0,4.6,1,6.1,2.7l1.5-0.9L46.9,28l-3.5-1.7l1.7-1   c-1.4-1.5-3.4-2.5-5.6-2.5c-4.2,0-7.6,3.4-7.6,7.6c0,3.8,2.9,7,6.6,7.5c2.4-1,5.1-1.7,8-1.7c10.3,0,18.7,8.4,18.7,18.7   C65.2,62.4,60.6,69,54,72h9.6h0.2v-3.9l7.2,4.7L63.7,77.5z"
        fill="none"
      />
      <path
        d="M50,5C25.1,5,5,25.1,5,50s20.1,45,45,45s45-20.1,45-45S74.9,5,50,5z M50,91C27.4,91,9,72.6,9,50   S27.4,9,50,9s41,18.4,41,41S72.6,91,50,91z"
        fill="#030303"
      />
      <path
        d="M63.8,72h-0.2H54c6.6-3,11.2-9.6,11.2-17.1c0-10.3-8.4-18.7-18.7-18.7c-2.9,0-5.6,0.7-8,1.7   c-3.7-0.5-6.6-3.7-6.6-7.5c0-4.2,3.4-7.6,7.6-7.6c2.2,0,4.2,1,5.6,2.5l-1.7,1l3.5,1.7l0.2-3.8l-1.5,0.9c-1.5-1.7-3.7-2.7-6.1-2.7   c-4.5,0-8.1,3.7-8.1,8.1c0,3.8,2.7,7.1,6.3,7.9c-2,1.1-3.7,2.5-5.2,4.2l-3.5-2l0.3,8.8l8-3.9l-3.7-2.2c3.2-3.5,7.7-5.7,12.9-5.7   c9.6,0,17.4,7.8,17.4,17.4c0,8.5-6.1,15.5-14.1,17.1h-0.5H34.9v1.5h11.5c0.1,0,0.2,0,0.3,0h17v3.9l7.3-4.7l-7.2-4.7V72z"
        fill="#020202"
      />
    </g>
  </svg>
);

export default Agile;
