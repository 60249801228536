import deDE from './de-DE/techStack.18n.json';
import en from './en/techStack.18n.json';

export const techStackLocales = {
  'de-DE': {
    techstack: deDE,
  },
  en: {
    techstack: en,
  },
};
