import React from 'react';

const Gulp = () => (
  <svg
    width="256px"
    height="566px"
    viewBox="0 0 256 566"
    version={1.1}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid"
  >
    <g>
      <path
        d="M197.28,548.749 L202.707,458.319 L217.692,432.739 C217.692,432.739 183.586,446.691 126.487,446.691 C69.387,446.691 35.798,432.997 35.798,432.997 L52.592,460.901 L58.533,548.749 C58.533,558.167 89.592,565.801 127.907,565.801 C166.22,565.801 197.28,558.167 197.28,548.749"
        fill="#D34A47"
      />
      <path
        d="M180.161,126.454 L191.552,81.429 L254.633,15.053 L238.007,0.892 L171.223,71.599 L157.997,125.392 C148.414,125.068 138.413,124.895 128.124,124.895 C57.788,124.895 0.769,132.911 0.769,142.797 C0.769,152.684 57.788,160.699 128.124,160.699 C198.459,160.699 255.477,152.684 255.477,142.797 C255.477,135.517 224.553,129.251 180.161,126.454"
        fill="#D34A47"
      />
      <path
        d="M173.535,151.669 C173.535,151.669 171.068,152.222 163.811,152.253 C156.552,152.283 151.398,152.206 149.345,150.336 C148.793,149.834 148.491,148.837 148.494,148.162 C148.5,146.877 149.412,146.12 150.574,145.646 L151.673,147.37 C151.195,147.535 150.922,147.73 150.921,147.938 C150.917,148.914 158.343,149.582 164.023,149.51 C169.704,149.44 176.568,148.972 176.574,148.01 C176.574,147.719 176.055,147.445 175.152,147.229 L176.246,145.517 C177.718,145.974 179.003,146.857 179,148.385 C178.986,150.983 175.68,151.378 173.535,151.669"
        fill="#FFFFFF"
      />
      <path
        d="M254.509,15.184 C256.095,13.352 253.688,8.666 249.133,4.719 C244.576,0.774 239.596,-0.94 238.007,0.892 C236.42,2.724 238.828,7.41 243.385,11.355 C247.94,15.301 252.922,17.017 254.509,15.184"
        fill="#D34A47"
      />
      <path
        d="M194.246,327.865 C192.751,313.481 225.708,292.649 217.25,291.982 C199.042,293.023 189.98,313.32 180.819,334.578 C177.462,342.369 165.975,375.591 158.441,371.501 C150.909,367.413 168.206,340.094 173.054,323.87 C167.46,327.972 146.945,343.911 141.658,328.984 C133.158,336.203 114.995,340.114 117.044,321.151 C112.522,329.161 102.373,340.344 90.191,335.697 C74.193,329.596 99.461,278.635 105.744,281.206 C112.028,283.776 104.476,295.385 102.498,299.893 C98.148,309.814 93.123,322.286 96.48,324.839 C102.231,329.213 117.881,308.292 118.229,307.832 C121.156,303.965 129.495,278.666 136.216,281.571 C142.937,284.476 119.452,317.879 128.233,324.508 C130.004,325.846 137.25,323.683 141.658,318.914 C144.51,315.829 143.529,308.996 153.036,286.324 C162.543,263.653 171.016,235.419 177.536,237.57 C184.056,239.72 178.682,254.211 176.343,259.613 C165.367,284.967 146.387,326.854 155.338,323.683 C164.289,320.513 168.95,320.327 177.715,310.07 C186.48,299.813 186.076,282.918 191.918,283.277 C197.762,283.637 196.781,289.054 195.364,293.179 C201.114,286.599 222.62,272.741 227.812,286.466 C233.962,302.715 197.043,326.186 206.248,324.989 C215.223,323.822 229.763,314.613 235.971,306.24 L253.059,152.614 C253.059,152.614 235.864,166.844 126.47,166.844 C17.075,166.844 1.659,152.91 1.659,152.91 L16.207,293.134 C23.929,271.187 42.266,226.795 71.17,228.285 C84.318,228.963 101.87,254.28 86.835,255.138 C80.471,255.5 79.825,242.501 72.289,240.592 C66.851,239.214 59.28,243.635 54.387,248.425 C44.629,257.975 23.642,295.654 26.415,314.439 C29.948,338.388 59.463,306.117 64.457,296.536 C67.974,289.787 70.35,269.791 78.042,271.889 C85.735,273.987 77.371,294.623 71.795,312.868 C65.531,333.355 62.238,354.966 54.387,352.48 C46.535,349.996 59.325,323.548 58.862,318.914 C51.482,325.723 37.886,342.961 20.492,334.429 L29.527,421.507 C29.527,421.507 53.54,440.48 125.877,440.48 C198.214,440.48 223.116,421.803 223.116,421.803 L234.335,320.946 C225.156,329.884 196.106,345.756 194.246,327.865"
        fill="#D34A47"
      />
    </g>
  </svg>
);

export default Gulp;
