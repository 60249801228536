import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import process from 'process';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { setPageTitle } from '../metadata/metadataDuck';
import { Image } from '../../components/image/index';
import { LinkCard } from '../../components/linkCard/index';
import ItsMe from '../../assets/images/itsme.png';
import ItsMe1 from '../../assets/images/itsme-1x.png';
import ItsMe2 from '../../assets/images/itsme-2x.png';
import ItsMe3 from '../../assets/images/itsme-3x.png';
import ItsMe4 from '../../assets/images/itsme-4x.png';

import { getAllLinkRelationsForLandindPage } from '../../services/linkRelations/index';
import { withScrollToTopDecorator } from '../../components/withScrollToTop';
import './landing.scss';

@withScrollToTopDecorator()
@connect(
  null,
  dispatch => ({
    propagatePageTitle: (...args) => dispatch(setPageTitle(...args)),
  }),
)
@translate(['linkRelation', 'landing'], { wait: process.browser })
export class Landing extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    // Through mapDispatchToProps
    propagatePageTitle: PropTypes.func.isRequired,
  };
  render() {
    const {
      t,
      propagatePageTitle,
    } = this.props;
    const linkRelations = getAllLinkRelationsForLandindPage();
    propagatePageTitle(t('landing:pageTitle'));
    return (
      <Fragment>
        <Helmet>
          <title>
            {t('landing:pageTitle')}
          </title>
          <meta name="description" content={t('landing:pageDescription')} />
        </Helmet>
        <header className="landing-header">
          <div className="landing-teaser" />
          <div className="landing-intro">
            <div className="it-is-me">
              <Image source={ItsMe} sourceSet={[ItsMe, ItsMe1, ItsMe2, ItsMe3, ItsMe4]} description="Its me!" width={160} height={160} isRound />
            </div>
            <div className="page-intro" dangerouslySetInnerHTML={{ __html: t('landing:introduction') }} />
          </div>
        </header>
        <nav className="landing-nav">
          {linkRelations.map(value => (
            <div className="link-card-container" key={value.id}>
              <LinkCard
                imageUrl={value.icon}
                linkDescription={t(value.description)}
                title={t(value.title)}
                linksTo={value.routerLink}
                href={value.href}
              />
            </div>
            ))}
        </nav>
      </Fragment>
    );
  }
}
