import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './card.scss';

export class Card extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  static defaultProps = {
  };

  render() {
    const {
      children,
    } = this.props;

    return (
      <div className="card">
        {children}
      </div>
    );
  }
}
