import { merge } from 'lodash';
import { footerLocales } from '../modules/footer/locales/i18nResources';
import { linkRelationLocales } from '../modules/linkRelations/locales/i18nResources';
import { landingLocales } from '../modules/landing/locales/i18nResources';
import { resumeLocales } from '../modules/resume/locales/i18nResources';
import { techStackLocales } from '../modules/techStack/locales/i18nResources';

export const locales = merge(
  {},
  footerLocales,
  linkRelationLocales,
  landingLocales,
  resumeLocales,
  techStackLocales,
);
