import React from 'react';

const Version = () => (
  <svg viewBox="0 0 100 100">
    <title />
    <path d="M18,77a9.011,9.011,0,0,0,8.768-7H73.232a9,9,0,1,0,0-4H43.092a9.067,9.067,0,0,0,.929-1.738L54.55,37.188A4.97,4.97,0,0,1,59.21,34H73.232a9,9,0,1,0,0-4H59.21a8.944,8.944,0,0,0-8.388,5.738L40.293,62.813A4.967,4.967,0,0,1,35.633,66H26.768A9,9,0,1,0,18,77ZM82,63a5,5,0,1,1-5,5A5.006,5.006,0,0,1,82,63Zm0-36a5,5,0,1,1-5,5A5.006,5.006,0,0,1,82,27ZM18,63a5,5,0,1,1-5,5A5.006,5.006,0,0,1,18,63Z" />
  </svg>
);

export default Version;
