import React from 'react';

const Couch = () => (
  <svg
    width="256px"
    height="204px"
    viewBox="0 0 256 204"
    version={1.1}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMinYMin meet"
  >
    <g>
      <path
        d="M234.286972,57.1713761 L234.286972,12.8234862 L21.7740917,12.8234862 L21.7740917,57.1713761 L5.14700917,57.1713761 L5.14700917,148.039633 L250.593468,148.039633 L250.593468,57.1713761 L234.286972,57.1713761 Z"
        fill="#FF0000"
      />
      <path
        d="M0.710458716,149.970202 L0.710458716,52.7336514 L17.3387156,52.7336514 L17.3387156,8.38693578 L238.722349,8.38693578 L238.722349,52.7336514 L255.02767,52.7336514 L255.02767,149.970202 L0.710458716,149.970202 L0.710458716,149.970202 Z M7.64477064,146.478972 L247.197358,146.689174 L246.159266,61.6067523 L229.848073,61.6067523 L229.848073,17.2576881 L26.2071193,17.2576881 L26.2071193,61.6067523 L9.57886239,61.6067523 L7.95713761,143.664147 L7.64477064,146.478972 L7.64477064,146.478972 Z"
        fill="#EB1019"
      />
      <path
        d="M32.8478532,8.01937615 L32.8478532,28.0049908 L40.4221651,38.2520367 L62.6871193,30.6918165 L62.5966972,0.514348624 L32.8478532,8.01937615 Z"
        fill="#FFFFFF"
      />
      <path
        d="M37.4593761,109.963743 L87.7046606,121.316991 L218.395009,109.14055 L218.425541,148.773578 L37.3583853,148.773578 L37.4593761,109.963743 Z"
        stroke="#BD101D"
        fill="#BD101D"
      />
      <path
        d="M186.996257,81.8354495 L173.339009,76.1693945 L144.938275,56.7803303 L111.006532,67.106055 L37.4264954,61.5351193 L37.4264954,87.6388991 L84.0830826,101.024881 L217.400367,89.1831193 L217.400367,61.9543486 L186.996257,81.8354495 Z"
        stroke="#BD101D"
        fill="#BD101D"
      />
      <path
        d="M17.3199266,44.2151927 L8.90950459,53.1117798 L8.90950459,76.9667523 L20.7841468,82.0209908 L20.7841468,68.242789 L25.837211,72.2025688 L25.837211,62.7892844 L37.4264954,62.7869358 L58.8764771,95.085211 L66.7866422,97.242422 L77.5280734,141.754716 L83.1013578,144.065761 L87.0435229,160.351119 L118.126385,160.351119 L103.178569,98.5729174 L145.404477,71.1550826 L168.369321,90.5594128 L173.948477,88.3740183 L176.065761,94.1434128 L217.400367,90.4337615 L213.812844,81.2764771 L190.556771,75.5892844 L148.447119,41.2794128 L102.564404,58.6838899 L88.4656147,40.1297615 L109.705394,18.5365138 L129.949358,18.5365138 L137.935853,25.2653211 L137.935853,18.5365138 L153.846606,18.5365138 L150.003083,9.60704587 L103.399339,2.22766972 L60.3760734,29.7817248 L17.3199266,44.2151927 Z"
        fill="#FFFFFF"
      />
      <g transform="translate(3.522936, 156.183486)" fill="#000000">
        <path d="M34.0644404,45.1957431 C32.1867156,46.1668991 28.0449174,47.2026422 22.802789,47.2026422 C8.95295413,47.2026422 0.539009174,38.4657615 0.539009174,25.197211 C0.539009174,10.8295046 10.5053945,2.1560367 23.8385321,2.1560367 C29.0806606,2.1560367 32.8349358,3.25754128 34.4531376,4.09717431 L32.7057615,10.439633 C30.6342752,9.53423853 27.7877431,8.75684404 24.1626422,8.75684404 C15.2954128,8.75684404 8.88836697,14.3230826 8.88836697,24.8073394 C8.88836697,34.3862018 14.5191927,40.5360734 24.098055,40.5360734 C27.3344587,40.5360734 30.7000367,39.8878532 32.7703486,38.9178716 L34.0644404,45.1957431 L34.0644404,45.1957431 Z" />
        <path d="M68.4283303,30.4405138 C68.4283303,42.0251009 60.2739083,47.2026422 52.2474862,47.2026422 C43.3156697,47.2026422 36.4553394,41.053945 36.4553394,30.957211 C36.4553394,20.6021284 43.2510826,14.2584954 52.7653578,14.2584954 C62.1492844,14.2596697 68.4283303,20.8616514 68.4283303,30.4405138 L68.4283303,30.4405138 Z M44.6743486,30.7634495 C44.6743486,36.8475596 47.6512294,41.4426422 52.5058349,41.4426422 C57.0363303,41.4426422 60.2081468,36.9767339 60.2081468,30.6342752 C60.2081468,25.7162569 58.0074862,20.0854312 52.5715963,20.0854312 C46.9395963,20.0854312 44.6743486,25.5201468 44.6743486,30.7634495 L44.6743486,30.7634495 Z" />
        <path d="M102.097028,37.0413211 C102.097028,40.7955963 102.226202,43.9016514 102.35655,46.4910092 L95.3658716,46.4910092 L94.9771743,41.7021651 L94.848,41.7021651 C93.4893211,43.9674128 90.3820917,47.2038165 84.7512661,47.2038165 C78.9912661,47.2038165 73.7479633,43.7736514 73.7479633,33.4819817 L73.7479633,14.9713028 L81.7086239,14.9713028 L81.7086239,32.1221284 C81.7086239,37.3642569 83.3914128,40.7298349 87.5977982,40.7298349 C90.7696147,40.7298349 92.8399266,38.4645872 93.6819083,36.4576881 C93.9414312,35.746055 94.1351927,34.9052477 94.1351927,33.9986789 L94.1351927,14.9701284 L102.095853,14.9701284 L102.095853,37.0413211 L102.097028,37.0413211 Z" />
        <path d="M132.699596,45.5198532 C131.015633,46.2972477 127.715817,47.2038165 123.76778,47.2038165 C113.930569,47.2038165 107.523523,40.8613578 107.523523,31.0875596 C107.523523,21.6378716 113.995156,14.2596697 125.063046,14.2596697 C127.975339,14.2596697 130.953394,14.9067156 132.765358,15.7486972 L131.342092,21.7024587 C130.046826,21.12 128.17145,20.4729541 125.322569,20.4729541 C119.238459,20.4729541 115.614532,24.9388624 115.679119,30.6988624 C115.679119,37.1716697 119.886679,40.8601835 125.322569,40.8601835 C128.105688,40.8601835 130.046826,40.2777248 131.60044,39.6306789 L132.699596,45.5198532 L132.699596,45.5198532 Z" />
        <path d="M137.281761,0.537834862 L145.307009,0.537834862 L145.307009,19.3068624 L145.437358,19.3068624 C146.407339,17.8178349 147.766018,16.5237431 149.38422,15.6829358 C150.936661,14.7775413 152.81556,14.2584954 154.821284,14.2584954 C160.192587,14.2584954 165.824587,17.8178349 165.824587,27.9145688 L165.824587,46.4898349 L157.863927,46.4898349 L157.863927,28.8211376 C157.863927,24.226055 156.179963,20.7947156 151.778642,20.7947156 C148.672587,20.7947156 146.470752,22.8662018 145.631119,25.2606239 C145.371596,25.908844 145.307009,26.7508257 145.307009,27.6562202 L145.307009,46.4898349 L137.281761,46.4898349 L137.281761,0.537834862 L137.281761,0.537834862 Z" />
        <path d="M173.495193,3.45012844 C176.991119,2.86766972 181.455853,2.54473394 186.181284,2.54473394 C194.335706,2.54473394 199.966532,4.22752294 203.979156,7.59310092 C208.186716,11.0232661 210.774899,16.2008073 210.774899,23.6447706 C210.774899,31.4104954 208.120954,37.2350826 203.979156,40.9905321 C199.643596,44.9385688 192.717505,46.9442936 184.238972,46.9442936 C179.578128,46.9442936 176.08455,46.6847706 173.495193,46.3618349 L173.495193,3.45012844 L173.495193,3.45012844 Z M181.455853,40.4068991 C182.556183,40.6006606 184.238972,40.6006606 185.857174,40.6006606 C196.212257,40.666422 202.360954,34.9698349 202.360954,23.901945 C202.426716,14.2584954 196.859303,8.75801835 186.892917,8.75801835 C184.369321,8.75801835 182.556183,8.95177982 181.455853,9.21130275 L181.455853,40.4068991 L181.455853,40.4068991 Z" />
        <path d="M216.783853,3.45012844 C219.30745,2.93343119 223.902532,2.54473394 228.36844,2.54473394 C234.258789,2.54473394 237.88389,3.25636697 240.860771,5.32785321 C243.578128,6.94605505 245.390092,9.79376147 245.390092,13.4822752 C245.390092,17.4948991 242.866495,21.1845872 238.141064,22.9319633 L238.141064,23.0611376 C242.737321,24.226055 246.943706,27.8499817 246.943706,33.8695046 C246.943706,37.7529541 245.260917,40.794422 242.737321,42.8659083 C239.630092,45.5832661 234.517138,46.9431193 226.556477,46.9431193 C222.155156,46.9431193 218.790752,46.6201835 216.783853,46.3606606 L216.783853,3.45012844 L216.783853,3.45012844 Z M224.679927,20.6667156 L228.758312,20.6667156 C234.259963,20.6667156 237.366018,18.0785321 237.366018,14.388844 C237.366018,10.3104587 234.258789,8.43508257 229.211596,8.43508257 C226.881761,8.43508257 225.521908,8.56425688 224.681101,8.75801835 L224.681101,20.6667156 L224.679927,20.6667156 Z M224.679927,40.7310092 C225.71567,40.9247706 227.075523,40.9247706 228.887486,40.9247706 C234.00044,40.9247706 238.530936,38.983633 238.530936,33.611156 C238.530936,28.562789 234.129615,26.4924771 228.627963,26.4924771 L224.679927,26.4924771 L224.679927,40.7310092 L224.679927,40.7310092 Z" />
      </g>
    </g>
  </svg>
);

export default Couch;
