import React from 'react';

const Css3 = () => (
  <svg
    width="298px"
    height="420px"
    viewBox="0 0 298 420"
    version={1.1}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Slice 1</title>
    <description>
      {'Created with Sketch (http://www.bohemiancoding.com/sketch)'}
    </description>
    <defs />
    <g id="Page 1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Layer_1">
        <path
          d="M233.164,15.681 L208.476,15.681 L234.15,43.54 L234.15,56.801 L181.268,56.801 L181.268,41.275 L206.937,41.275 L181.268,13.415 L181.268,0.135 L233.164,0.135 L233.164,15.681 L233.164,15.681 Z M170.786,15.681 L146.098,15.681 L171.771,43.54 L171.771,56.801 L118.889,56.801 L118.889,41.275 L144.561,41.275 L118.889,13.415 L118.889,0.135 L170.786,0.135 L170.786,15.681 L170.786,15.681 Z M109.018,16.312 L82.378,16.312 L82.378,40.625 L109.018,40.625 L109.018,56.801 L63.444,56.801 L63.444,0.135 L109.018,0.135 L109.018,16.312 L109.018,16.312 Z M109.018,16.312"
          id="Shape"
          fill="#131313"
        />
        <path
          d="M27.142,386.29 L0.071,82.67 L297.521,82.67 L270.425,386.241 L148.614,420.011 L27.142,386.29 Z M27.142,386.29"
          id="Shape"
          fill="#1572B6"
        />
        <path
          d="M148.798,394.199 L247.225,366.911 L270.382,107.496 L148.798,107.496 L148.798,394.199 Z M148.798,394.199"
          id="Shape"
          fill="#33A9DC"
        />
        <g id="Group" transform="translate(55.000000, 142.000000)">
          <path
            d="M93.797,75.496 L143.072,75.496 L146.475,37.364 L93.797,37.364 L93.797,0.125 L93.926,0.125 L187.172,0.125 L186.279,10.116 L177.127,112.732 L93.797,112.732 L93.797,75.496 Z M93.797,75.496"
            id="Shape"
            fill="#FFFFFF"
          />
          <path
            d="M94.02,172.204 L93.857,172.25 L52.385,161.051 L49.733,131.353 L29.582,131.353 L12.354,131.353 L17.57,189.82 L93.848,210.996 L94.02,210.948 L94.02,172.204 Z M94.02,172.204"
            id="Shape"
            fill="#EBEBEB"
          />
          <path
            d="M139.907,111.156 L135.423,161.026 L93.891,172.236 L93.891,210.978 L170.23,189.82 L170.79,183.53 L177.268,111.156 L139.907,111.156 Z M139.907,111.156"
            id="Shape"
            fill="#FFFFFF"
          />
          <path
            d="M93.926,0.125 L93.926,23.253 L93.926,37.272 L93.926,37.364 L4.098,37.364 L3.979,37.364 L3.232,28.994 L1.535,10.116 L0.645,0.125 L93.926,0.125 Z M93.926,0.125"
            id="Shape"
            fill="#EBEBEB"
          />
          <path
            d="M93.797,75.5 L93.797,98.629 L93.797,112.646 L93.797,112.738 L52.969,112.738 L52.85,112.738 L52.104,104.369 L50.406,85.491 L49.516,75.5 L93.797,75.5 Z M93.797,75.5"
            id="Shape"
            fill="#EBEBEB"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Css3;
