import React from 'react';

const Angular2 = () => (
  <svg
    width="374px"
    height="400px"
    viewBox="0 0 374 400"
    version={1.1}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>angular-2</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="angular-2" transform="translate(-1.000000, 0.000000)">
        <g id="Layer_1">
          <g id="Group">
            <polygon
              id="Shape"
              fill="#DD0031"
              points="188 0 188 0 188 0 1.8 66.4 30.2 312.6 188 400 188 400 188 400 345.8 312.6 374.2 66.4"
            />
            <polygon
              id="Shape"
              fill="#C3002F"
              points="188 0 188 44.4 188 44.2 188 246.8 188 246.8 188 400 188 400 345.8 312.6 374.2 66.4"
            />
            <path
              d="M188,44.2 L71.6,305.2 L71.6,305.2 L115,305.2 L115,305.2 L138.4,246.8 L237.2,246.8 L260.6,305.2 L260.6,305.2 L304,305.2 L304,305.2 L188,44.2 L188,44.2 L188,44.2 L188,44.2 L188,44.2 Z M222,210.8 L154,210.8 L188,129 L222,210.8 Z"
              id="Shape"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Angular2;
