import React from 'react';

const W3C = () => (
  <svg
    xmlSpace="preserve"
    version={1.1}
    overflow="visible"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 72 48.139"
  >
    <g
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
    >
      <g
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
      >
        <g id="Background" />
        <g id="Guides" />
        <g id="Foreground">
          <rect
            fillRule="evenodd"
            width={72}
            fill="#FFFFFF"
            clipRule="evenodd"
            height={48}
          />
          <rect
            width={71.861}
            fillRule="evenodd"
            clipRule="evenodd"
            height={1.108}
          />
          <rect
            y={47.03}
            width={71.861}
            fillRule="evenodd"
            clipRule="evenodd"
            height={1.108}
          />
          <g>
            <path
              d="M20.149,8.815l5.759,19.58l5.759-19.58h4.17H47.28v1.946l-5.878,10.127    c2.065,0.663,3.627,1.868,4.686,3.615c1.059,1.747,1.589,3.799,1.589,6.155c0,2.913-0.774,5.362-2.323,7.348    s-3.555,2.978-6.017,2.978c-1.854,0-3.469-0.589-4.845-1.767c-1.377-1.178-2.396-2.773-3.058-4.786l3.256-1.35    c0.477,1.218,1.106,2.178,1.887,2.879c0.781,0.702,1.701,1.052,2.76,1.052c1.112,0,2.052-0.622,2.82-1.866    c0.768-1.245,1.152-2.74,1.152-4.488c0-1.933-0.411-3.429-1.231-4.488c-0.954-1.244-2.45-1.866-4.489-1.866h-1.588v-1.906    l5.561-9.612h-6.712l-0.382,0.65l-8.163,27.548h-0.397L19.95,21.048l-5.958,19.937h-0.397L4.064,8.815h4.17l5.759,19.58    l3.893-13.185l-1.906-6.395H20.149z"
              fill="#005A9C"
            />
          </g>
          <path
            clipRule="evenodd"
            d="M68.952,8.815c-0.819,0-1.554,0.295-2.111,0.861   c-0.591,0.6-0.92,1.376-0.92,2.178c0,0.802,0.313,1.545,0.887,2.128c0.583,0.591,1.334,0.912,2.145,0.912   c0.793,0,1.562-0.321,2.161-0.903C71.688,13.434,72,12.69,72,11.854c0-0.811-0.321-1.57-0.878-2.136   C70.539,9.127,69.779,8.815,68.952,8.815z M71.595,11.88c0,0.701-0.271,1.351-0.769,1.832c-0.523,0.507-1.173,0.777-1.891,0.777   c-0.675,0-1.342-0.278-1.84-0.785c-0.498-0.506-0.777-1.157-0.777-1.849s0.287-1.368,0.802-1.891   c0.481-0.49,1.131-0.751,1.84-0.751c0.726,0,1.376,0.271,1.883,0.785C71.333,10.487,71.595,11.146,71.595,11.88z M69.037,10.073   h-1.3v3.445h0.65v-1.469h0.642l0.701,1.469h0.726l-0.769-1.571c0.498-0.101,0.785-0.439,0.785-0.928   C70.472,10.394,69.999,10.073,69.037,10.073z M68.918,10.495c0.608,0,0.886,0.169,0.886,0.591c0,0.405-0.278,0.549-0.87,0.549   h-0.549v-1.14H68.918z"
            fillRule="evenodd"
          />
        </g>
        <g id="Calque_1">
          <g>
            <path d="M63.839,8.634l0.676,4.107l-2.391,4.575c0,0-0.918-1.941-2.443-3.015c-1.285-0.905-2.122-1.102-3.431-0.832    c-1.681,0.347-3.587,2.357-4.419,4.835c-0.995,2.965-1.005,4.4-1.04,5.718c-0.056,2.113,0.277,3.362,0.277,3.362    s-1.451-2.686-1.438-6.62c0.009-2.808,0.451-5.354,1.75-7.867c1.143-2.209,2.842-3.535,4.35-3.691    c1.559-0.161,2.791,0.59,3.743,1.404c0.999,0.854,2.01,2.72,2.01,2.72L63.839,8.634z" />
            <path d="M64.134,31.872c0,0-1.057,1.889-1.715,2.617c-0.659,0.728-1.837,2.01-3.292,2.651c-1.456,0.641-2.218,0.762-3.656,0.624    c-1.437-0.138-2.773-0.97-3.241-1.317s-1.664-1.369-2.339-2.322c-0.676-0.954-1.733-2.859-1.733-2.859s0.589,1.911,0.958,2.721    c0.212,0.466,0.864,1.894,1.79,3.136c0.862,1.159,2.539,3.154,5.086,3.604c2.547,0.451,4.297-0.693,4.73-0.971    c0.433-0.277,1.346-1.041,1.924-1.659c0.603-0.645,1.174-1.468,1.49-1.962c0.231-0.36,0.607-1.092,0.607-1.092L64.134,31.872z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default W3C;
