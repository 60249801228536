import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './footer.scss';

export class FooterTemplate extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };
  render() {
    const {
      t,
    } = this.props;
    const routeToImprint = '/imprint';
    return (
      <footer>
        <section>
          <span dangerouslySetInnerHTML={{ __html: `${t('footer:copyright')} - ${t('footer:name')}` }} />
          <Link to={routeToImprint}>
            {` - ${t('footer:imprint')}`}
          </Link>
        </section>
      </footer>
    );
  }
}
