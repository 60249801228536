import React from 'react';

const Db = () => (
  <svg viewBox="0 0 100 100">
    <title />
    <path d="M50,11c-17.89,0-36,4.122-36,12V77c0,7.878,18.11,12,36,12s36-4.122,36-12V23C86,15.122,67.89,11,50,11Zm0,74c-21.01,0-32-5.208-32-8V64.818C24.32,68.894,37.215,71,50,71s25.68-2.106,32-6.182V77C82,79.792,71.01,85,50,85Zm0-18c-21.01,0-32-5.208-32-8V46.818C24.32,50.894,37.215,53,50,53s25.68-2.106,32-6.182V59C82,61.792,71.01,67,50,67Zm0-18c-21.01,0-32-5.208-32-8V28.818C24.32,32.894,37.215,35,50,35s25.68-2.106,32-6.182V41C82,43.792,71.01,49,50,49Zm0-18c-21.01,0-32-5.208-32-8s10.99-8,32-8,32,5.208,32,8S71.01,31,50,31Z" />
  </svg>
);

export default Db;
