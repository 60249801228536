import HomepageImage from '../../assets/images/homepage_screens.png';
import Outfittary from '../../assets/images/outfittary.png';
import RheinEnergie from '../../assets/images/rheinEnergie.png';
import IKB from '../../assets/images/ikb.png';
import TSystems from '../../assets/images/tsystems.png';
import Arago from '../../assets/images/arago.png';


const projects = [
  {
    title: 'Homepage – manuel.spoerer.de',
    image: HomepageImage,
    desc: 'Neuentwicklung meiner eigenen Homepage - manuel.spoerer.de. Umsetzung erfolgte mittels Angular 2, Express & CouchDB. Das Design der Seite orientiert sich an der Material Design Spezifikation von Google.',
    tech: 'AngularJS 2, RxJS, Webpack, NodeJS, Karma, Jasmine, Angular Material, SASS, Express, CouchDB, Git, Scrum',
    startDate: new Date(2016, 11, 1).toUTCString(),
    endDate: new Date(2017, 1, 1).toUTCString(),
  },
  {
    title: 'Outfittery – Sales funnel',
    image: Outfittary,
    desc: 'Entwicklung eines online “Sales funnels” basierend auf verschiedenen workflow steps und individuellen skip conditions. Aufbau und Layout der Webseite orientiert sich am vorgegebene Responsive Design.',
    tech: 'AngularJS 1.5, Webpack, NodeJS, Karma, Jasmine, Twitter Bootstrap, SASS, Git, Scrum',
    startDate: new Date(2016, 8, 1).toUTCString(),
    endDate: new Date(2016, 10, 1).toUTCString(),
  },
  {
    title: 'RheinEnergie – Preisanpassungstool',
    image: RheinEnergie,
    desc: 'Entwicklung eines Order Management Tools zur Erstellung, Anpassung und Stornierung von Rahmentarifen zwischen Energieversorgungsunternehmen. Mitverantwortlich für die Systemarchitektur, sowie Projektdurchführung als Lead Front End Developer.',
    tech: 'CoffeeScript, Haml, AngularJS 1.5, JQuery , Ajax, Gulp, NPM, Bower, Karma, Jasmine, Twitter Bootstrap, Less, NodeJS, MongoDB, Git, Scrum, JEE, PostgreSQL, Selenium, Serenity (BDD), jBehave',
    startDate: new Date(2016, 3, 1).toUTCString(),
    endDate: new Date(2016, 6, 1).toUTCString(),
  },
  {
    title: 'IKB – Online Banking',
    image: IKB,
    desc: 'Konzeptionierung und Umsetzung eines Online Banking Systems anhand von vordefinierten Anforderungen. Verantwortlich für die Projektdurchführung als Lead Front End Developer.',
    tech: 'JScript, AngularJS 1.5, JQuery , Ajax, Gulp, NPM, Bower, Karma, Jasmine, Twitter Bootstrap, Less, Git, Scrum',
    startDate: new Date(2016, 0, 1).toUTCString(),
    endDate: new Date(2016, 3, 1).toUTCString(),
  },
  {
    title: 'T-Systems – E-Procurement & Order Management',
    image: TSystems,
    desc: 'Entwicklung einer Web-Applikation im Bereich Order Management / E- Procurement. Implementierung von modernen, zeitgemäßen Benutzerschnittstellen, die umfangreichen Anforderungen an barrierearme Benutzerschnittstellen genügen.',
    tech: 'JScript, Angular 1.5, JQuery, ExtJS, Ajax, Grunt, NPM, Bower, Karma, Jasmine, Spring, Maven 3, Liquibase, Scrum, Git',
    startDate: new Date(2015, 2, 1).toUTCString(),
    endDate: new Date(2016, 0, 1).toUTCString(),
  },
  {
    title: 'Bubble – Web Katalogisierung',
    image: '',
    desc: 'Konzeptionierung und Umsetzung eines Chrome Plugins zur Erfassen von Bookmarks inkl. Katalogisierungs- und Kommentarfunktionen. Verantwortlich für die Systemarchitektur, sowie Projektdurchführung als Lead Front End Developer.',
    tech: 'JScript, AngularJS 1.3, JQuery , Ajax, Grunt, NPM, Bower, Mocha JS, Should.js, NodeJS, Express, MongoDB, Twitter Bootstrap, Local Storage, AppCache, Scrum, Git',
    startDate: new Date(2014, 6, 1).toUTCString(),
    endDate: new Date(2014, 10, 1).toUTCString(),
  },
  {
    title: 'Topomomo – Topography of the modern movement',
    image: '',
    vimeo: 'https://player.vimeo.com/video/116135653',
    desc: 'Umsetzung einer responsive Single-Page-Webanwendung mit dem Augenmerk zur mobilen Offlinefähigkeit. Verantwortlich für die Projektdurchführung als Lead Developer.',
    tech: 'JScript, Ember.js, JQuery, Ajax, Grunt, NodeJS, Express, NPM, Bower, Mocha JS, Should.js, Twitter Bootstrap, Local Storage, AppCache, Scrum, Git',
    startDate: new Date(2014, 2, 1).toUTCString(),
    endDate: new Date(2014, 5, 1).toUTCString(),
  },
  {
    title: 'Appeatizer - Backend für IOS App',
    image: '',
    youtube: 'https://www.youtube.com/embed/ITvqO0P5A1g?color=white',
    desc: 'Entwicklung eines RESTful Webservice inkl. Socket Connection auf Basis vordefinierter Anforderungen mittels Node JS und Express (Web Applikation Service Framework)',
    tech: 'JScript, NodeJS, Express, Grunt, NPM, MochaJS, Should.js, Socket.io, Winston (Logging Framework), CouchDB',
    startDate: new Date(2014, 3, 1).toUTCString(),
    endDate: new Date(2014, 3, 1).toUTCString(),
  },
  {
    title: 'Deutsche Bank',
    image: Arago,
    desc: 'Entwicklung eines automatisiertes Hardware/Software Integration/ Deployment Systems. Umsetzung grafischer Entwürfe in klar strukturierte HTML, CSS und JavascriptCodes. Mitarbeit in interdisziplinären und agilen Entwicklungsteams.',
    tech: 'JavaScript, AngularJS 1.1, JQuery, Ajax, HTML5, Node, Grunt, Bower, Handlebars, Twitter Bootstrap, QUnit, TestemJS, Less, Foundation, Kanban',
    startDate: new Date(2013, 7, 1).toUTCString(),
    endDate: new Date(2014, 2, 1).toUTCString(),
  },
  {
    title: 'Syngenio AG – Next Generation Finance Online Banking System',
    image: '',
    desc: 'Konzeptionierung und Umsetzung eines Online Banking Systems anhand von vordefinierten Anforderungen. Entwicklung der Frontend Applikation mittels des JScirpt Framework Backbone.js',
    tech: 'JavaScript, JQuery, Ajax, HTML5, Node, Grunt, Bower, ExpressJS, Backbone.js, Karma, Mocha, Should.js, Twitter Bootstrap, Less, Scrum',
    startDate: new Date(2013, 2, 1).toUTCString(),
    endDate: new Date(2013, 6, 1).toUTCString(),
  },
  {
    title: 'AOK – iOS Migration Gesundheitsvorsorge App',
    image: '',
    desc: 'IOSMigration einer auf Android entwickelten VorsorgemanagerApp für Menschen mit türkischem Migrationshintergrund.',
    tech: 'PhoneGap, JScript, HTML, CSS, JQuery, Java, SQLLite, Jasmine',
    startDate: new Date(2013, 0, 1).toUTCString(),
    endDate: new Date(2013, 1, 1).toUTCString(),
  },
  {
    title: 'DeutscheLeasing – Showcase Touch ButtonCard',
    image: '',
    desc: 'Entwicklung eines Mobile WebApp Prototypens zur Erkennung von Touch- Code Karten.',
    tech: 'Script, HTML, CSS, PhoneGap',
    startDate: new Date(2012, 11, 1).toUTCString(),
    endDate: new Date(2012, 11, 1).toUTCString(),
  },
  {
    title: 'GAD – nPA Showcase',
    image: '',
    desc: 'Entwicklung einer WebApplikation zur Demonstration einer Authentifizierung mittels des neuen Personalausweises.',
    tech: 'Spring, Java, HTML, CSS',
    startDate: new Date(2012, 10, 1).toUTCString(),
    endDate: new Date(2012, 10, 1).toUTCString(),
  },
  {
    title: 'Create Dystopia – User Generated Cinema',
    image: '',
    vimeo: 'https://player.vimeo.com/video/47085576',
    desc: 'Konzeptionierung und Implementierung eines Social Collaborative Netzwerks (Create Dystopia) zur transparenten Entwicklung eines Creative Commons Films. Festlegung funktionaler Anforderungen an eine Social Collaborative Learning Platform. Umsetzung des Web-Frontends anhand bestehender Anforderungen. Anpassung bzw. Erweiterung der Datenstruktur an bestehende Anforderungen.',
    tech: 'Eclipse, PHP, JScript, HTML, CSS, JQuery, SVN, UML, SQL, MySQL, SVN, Kanban',
    startDate: new Date(2012, 2, 1).toUTCString(),
    endDate: new Date(2012, 9, 1).toUTCString(),
  },
];

export const getAllProjects = () => projects;
