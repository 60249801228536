import { createLogger } from 'ms-iso-logger';

const logger = createLogger('CreateDefaultState - 0001');

export const createDefaultState = () => {
  logger.info('create state');
  return {
    locale: '',
  };
};
