import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HamburgerMenu from 'react-hamburger-menu';
import CheeseburgerMenu from 'cheeseburger-menu';
import { createLogger } from 'ms-iso-logger';
import { NavLink, Link } from 'react-router-dom';
import { Image } from '../../components/image/Image';

import ItsMe from '../../assets/images/itsme.png';
import ItsMe1 from '../../assets/images/itsme-1x.png';
import ItsMe2 from '../../assets/images/itsme-2x.png';
import ItsMe3 from '../../assets/images/itsme-3x.png';
import ItsMe4 from '../../assets/images/itsme-4x.png';

import '../../../../node_modules/@angular/material/toolbar/toolbar.scss';
import './navigation.scss';

const logger = createLogger('NavigationTemplate');


export class NavigationTemplate extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    linkRelations: PropTypes.array.isRequired,
    pageTitle: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    logger.debug('NavigationTemplate initialized');
    this.state = {
      menuOpen: false,
    };
  }

  openMenu() {
    this.setState({ menuOpen: true });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
    const {
      t,
      linkRelations,
      pageTitle,
    } = this.props;

    return (
      <nav>
        <md-toolbar class="nav-bar">
          <md-toolbar-row class="nav-bar-desktop">
            <Link className="home" to="/">
              <Image
                source={ItsMe}
                sourceSet={[ItsMe, ItsMe1, ItsMe2, ItsMe3, ItsMe4]}
                isRound
                description="It's me"
              />
            </Link>
            { linkRelations.map(link => (
              <NavLink key={link.id} to={link.routerLink} activeClassName={link.isActiveEnabled ? 'is-active' : ''} className="nav-button">
                {t(link.title)}
              </NavLink>
            ))}
            <a href="mailto: manuel@scolp.de" className="nav-button align-right" md-button="true">
              {t('linkRelation:contact.title')}
            </a>
          </md-toolbar-row>
          <md-toolbar-row class="nav-bar-mobile">
            <Link className="home" to="/">
              <Image
                source={ItsMe}
                sourceSet={[ItsMe, ItsMe1, ItsMe2, ItsMe3, ItsMe4]}
                isRound
                description="It's me"
              />
            </Link>
            <div className="mobile-page-title nav-button">
              {pageTitle}
            </div>
            <div className="mobile-menu-open">
              <HamburgerMenu
                isOpen={this.state.menuOpen}
                menuClicked={() => {
                  this.openMenu();
                }}
                width={18}
                height={15}
                strokeWidth={1}
                rotate={0}
                color="white"
                borderRadius={0}
                animationDuration={0.5}
              />
            </div>
          </md-toolbar-row>
          <CheeseburgerMenu
            isOpen={this.state.menuOpen}
            closeCallback={() => {
              this.closeMenu();
            }}
            right
            backgroundColor="#3a4250"
            noShadow
          >
            <div className="mobile-menu-close">
              <HamburgerMenu
                isOpen={this.state.menuOpen}
                menuClicked={() => {
                  this.closeMenu();
                }}
                width={18}
                height={15}
                strokeWidth={1}
                rotate={0}
                color="white"
                borderRadius={0}
                animationDuration={0.5}
              />
            </div>
            <div className="mobile-menu">
              <div className="mobile-menu-logo">
                <Link className="home" to="/">
                  <Image
                    source={ItsMe}
                    sourceSet={[ItsMe, ItsMe1, ItsMe2, ItsMe3, ItsMe4]}
                    isRound
                    description="It's me"
                  />
                </Link>
              </div>
            </div>
            <div className="mobile-menu">
              { linkRelations.map(link => (
                <div className="mobile-menu-item" key={link.id}>
                  <NavLink
                    to={link.routerLink}
                    activeClassName={link.isActiveEnabled ? 'is-active' : ''}
                    className="nav-button"
                    onClick={() => {
                      this.closeMenu();
                      return true;
                    }}
                  >
                    {t(link.title)}
                  </NavLink>
                </div>
              ))}
              <div className="mobile-menu-item">
                <a href="mailto: manuel@scolp.de" className="nav-button align-right" md-button="true">
                  {t('linkRelation:contact.title')}
                </a>
              </div>
            </div>
            <div className="mobile-menu mobile-menu-footer">
              <div className="text" dangerouslySetInnerHTML={{ __html: t('footer:copyright') }} />
              <div className="text">{t('footer:name')}</div>
              <NavLink to="/imprint" className="text">{t('footer:imprint')}</NavLink>
            </div>
          </CheeseburgerMenu>
        </md-toolbar>
      </nav>
    );
  }
}
