import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { translate } from 'react-i18next';
import process from 'process';
import { getAllProjects } from '../../services/projects/projects';
import { TimelineTemplate } from '../../components/timeline';
import { setPageTitle } from '../metadata/metadataDuck';
import { withScrollToTopDecorator } from '../../components/withScrollToTop';


@connect(
  null,
  dispatch => ({
    propagatePageTitle: (...args) => dispatch(setPageTitle(...args)),
  }),
)
@translate(['resume'], { wait: process.browser })
@withScrollToTopDecorator()
export class Resume extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    // Through mapDispatchToProps
    propagatePageTitle: PropTypes.func.isRequired,
  };
  render() {
    const {
      t,
      propagatePageTitle,
    } = this.props;

    const projects = getAllProjects();
    propagatePageTitle(t('resume:pageTitle'));
    return (
      <Fragment>
        <Helmet>
          <title>
            {t('resume:pageTitle')}
          </title>
          <meta name="description" content={t('resume:pageDescription')} />
        </Helmet>
        <div className="resume-content-wrapper">
          {
            /*
              <div
                className="page-intro"
                dangerouslySetInnerHTML={{ __html: t('resume:introduction') }}
              />
            */
          }
          <TimelineTemplate projects={projects} endPointDescription={t('resume:timelineEndPointDescription')} />
        </div>
      </Fragment>
    );
  }
}
