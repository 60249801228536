import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { translate } from 'react-i18next';
import process from 'process';
import { setPageTitle } from '../metadata/metadataDuck';
import { withScrollToTopDecorator } from '../../components/withScrollToTop';
import './notFound.css';

@connect(
  null,
  dispatch => ({
    propagatePageTitle: (...args) => dispatch(setPageTitle(...args)),
  }),
)
@translate(['resume'], { wait: process.browser })
@withScrollToTopDecorator()
export class NotFound extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    // Through mapDispatchToProps
    propagatePageTitle: PropTypes.func.isRequired,
  };

  render() {
    const {
      t,
      propagatePageTitle,
    } = this.props;

    propagatePageTitle(t('login:pageTitle'));
    return (
      <Fragment>
        <Helmet>
          <title>
            {t('login:pageTitle')}
          </title>
          <meta name="description" content={t('login:pageDescription')} />
        </Helmet>
        <div id="notfound">
          <div className="notfound">
            <div className="notfound-404">
              <h3>Oops! Page not found</h3>
              <h1><span>4</span><span>0</span><span>4</span></h1>
            </div>
            <h2>we are sorry, but the page you requested was not found</h2>
          </div>
        </div>
      </Fragment>
    );
  }
}
