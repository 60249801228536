import i18n from 'i18next';
import { clone } from 'lodash';
import UIConfig from 'electrode-ui-config';
import { createLogger } from 'ms-iso-logger';
import { locales } from './locales';

const logger = createLogger('i18n - getI18nInstance');


const uiConfig = Object.assign({}, UIConfig.ui);

const createI18nInstance = (locale) => {
  const i18nOptions = clone(uiConfig.i18n);
  logger.debug(`locale - ${locale}`);
  if (locale) {
    i18nOptions.lng = locale;
  }
  logger.debug(JSON.stringify(i18nOptions));
  const newInstance = i18n.createInstance();
  i18nOptions.resources = locales;
  return newInstance.init(i18nOptions);
};

let i18nInstance = null;

export function getI18nInstance(locale) {
  if (!i18nInstance) {
    i18nInstance = createI18nInstance(locale);
    return i18nInstance;
  }
  i18nInstance.changeLanguage(locale);
  return i18nInstance;
}
