import React, { Component } from 'react';
import PropTypes from 'prop-types';
import process from 'process';
import { createLogger } from 'ms-iso-logger';
import { translate } from 'react-i18next';
import { FooterTemplate } from './FooterTemplate';

const logger = createLogger('Footer');


@translate(['footer'], { wait: process.browser })
export class Footer extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    logger.debug('Footer initialized');
  }

  render() {
    const { t } = this.props;

    return (
      <FooterTemplate t={t} />
    );
  }
}
