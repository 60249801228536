import React, { Component } from 'react';
import PropTypes from 'prop-types';
import process from 'process';
import { connect } from 'react-redux';
import { createLogger } from 'ms-iso-logger';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import ReactRouterPropTypes from 'react-router-prop-types';
import { NavigationTemplate } from './NavigationTemplate';
import { getLinkRelationsForNavBar } from '../../services/linkRelations/linkRelations';
import { selectPageTitle } from '../metadata/metadataDuck';

const logger = createLogger('Navigation');

@withRouter
@connect(state => ({ pageTitle: selectPageTitle(state) }))
@translate(['linkRelation', 'footer'], { wait: process.browser })
export class Navigation extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    pageTitle: PropTypes.string.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
  };

  constructor() {
    super();
    logger.debug('Navigation initialized');
  }

  render() {
    const {
      t,
      pageTitle,
      location,
    } = this.props;

    const { pathname } = location;
    logger.debug(`${pathname}`);
    logger.debug('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    const linkRelations = getLinkRelationsForNavBar();
    return (pathname === '/' || pathname === '/login') || (
      <NavigationTemplate
        t={t}
        linkRelations={linkRelations}
        pageTitle={pageTitle}
      />
    );
  }
}
